import React from "react";
import "../styles/MoneyBackGuarantee.css";
import { FaCertificate, FaCheckCircle, FaShieldAlt } from "react-icons/fa";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import MoneyBackImage from "../Assets/HomeMoneyBackSection.jpg";
const MoneyBackGuarantee = () => {
  return (
    <div className="discount-section d-flex flex-column justify-content-center align-content-center">
      <img src={MoneyBackImage} alt="MoneyBackGuarantee" loading="lazy" />
    </div>
  );
};

export default MoneyBackGuarantee;
// import React from "react";
// import "../styles/MoneyBackGuarantee.css";
// import { FaCertificate, FaCheckCircle, FaShieldAlt } from "react-icons/fa";
// import { Button } from "primereact/button";
// import { Link } from "react-router-dom";

// const MoneyBackGuarantee = () => {
//   return (
//     <div className="discount-section d-flex flex-column justify-content-center align-content-center">
//       {/* <div className="money-back-guarantee d-flex flex-column"> */}
//       <div className="content-box shadow-lg p-4 ">
//         <h2 className="">
//           100% Money Back Guarantee <FaShieldAlt />
//         </h2>
//         <p className="">
//           <strong>
//             We offer a 100% Money Back Guarantee if the instructor is not
//             arranged within two weeks.
//             {/* We are giving 100% Money Back Guarantee if the instructor is not
//             arranged within two weeks <em>T&C Applied</em> */}
//           </strong>
//           <em>T&C Applied</em>
//         </p>
//         {/* <p className="discount-text">
//           We stand behind our promise to connect you with a qualified instructor
//           within two weeks. If we fall short, you’ll receive a 100% refund,
//           ensuring that your trust in us is never misplaced.
//         </p> */}
//         {/* <div className="text-center">
//           <Link to="/terms-conditions">
//             <Button
//               label="Learn More"
//               className="p-button-rounded p-button-raised p-button-sm with-icon-button
//                         discount-button  button-primary p-py-1 p-px-3 bg-hover-tertiary "
//             />
//           </Link>
//         </div> */}

//         {/* <p>
//           <em>Terms & Conditions applied</em>
//         </p> */}
//         {/* <hr /> */}
//         <p className="horizantal-line" />
//         <h6>Terms & Conditions</h6>
//         <small>
//           <ul>
//             <li>
//               The deposit will be refunded if we are unable to arrange an
//               instructor within two weeks.
//             </li>
//             <li>
//               If the customer changes their mind within two weeks, no refund
//               will be provided.
//             </li>
//             <li>
//               Once an instructor has been arranged and has made contact with the
//               customer, no refund will be issued.
//             </li>
//           </ul>
//         </small>
//         <div className="text-center">
//           {/* <Link to="/terms-conditions"> */}
//           <Button
//             label="Learn More"
//             className="p-button-rounded p-button-raised p-button-sm with-icon-button
//                         discount-button  button-primary p-py-1 p-px-3 bg-hover-tertiary "
//           />
//           {/* </Link> */}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default MoneyBackGuarantee;
