import { Avatar } from "primereact/avatar";
import { Menubar } from "primereact/menubar";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../contexts/UserContext";

const LoggedInHeader = ({ handleLogOut }) => {
  const { isLoggedIn } = useContext(UserContext);

  const [showingContextMenu, setShowingContextMenu] = useState(false);
  const handleOutsideClick = (e) => {
    if (!e.target.closest(".context-menu") && !e.target.closest(".avatar")) {
      setShowingContextMenu(false);
    }
  };
  useEffect(() => {
    if (showingContextMenu) {
      document.addEventListener("click", handleOutsideClick);
    } else {
      document.removeEventListener("click", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [showingContextMenu]);

  const userRole = localStorage.getItem("userRole");
  const profileUrl =
    JSON.parse(userRole) === "customer"
      ? "/student/profile"
      : "/instructor/profile";
  const Contextitems = [
    {
      label: "Profile",
      icon: "pi pi-user",
      url: profileUrl,
      className: "context-menu-item",
    },
    isLoggedIn && {
      label: "Logout",
      className: "context-menu-item",
      icon: "pi pi-sign-out",
      command: () => handleLogOut(),
    },
  ];
  return (
    <div
      // className="contacts"
      className=" background-primary"
      // style={{
      //   backgroundColor: "#023047",
      // }}
    >
      {/* <div className="container-md"> */}
      <ul className="nav justify-content-between align-items-center container">
        <div className="m-3 contact-container"></div>

        <div className="flex-end m-0 mt-1 mb-1 p-0 text-white justify-content-center align-items-center d-flex position-relative text-white-50">
          <Avatar
            onClick={() => {
              setShowingContextMenu(!showingContextMenu);
            }}
            image="https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y"
            shape="circle"
            className="avatar"
            size="normal"
          />
          {showingContextMenu && (
            <Menubar
              model={
                JSON.parse(userRole) === "admin"
                  ? Contextitems.slice(1)
                  : Contextitems
              }
              className="dropdown-menubar"
            />
          )}
        </div>
      </ul>
      {/* </div> */}
    </div>
  );
};

export default LoggedInHeader;
