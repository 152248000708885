import React from "react";

const PrivacyPolicy = () => {
  return (
    <div style={{ padding: "20px" }} className="container">
      <h1>Privacy Policy</h1>
      <p>
        This privacy policy notice is for this website; www.pass-swiftly.com and
        served by pass swiftly Ltd, 3 Loganswel Drive G468QL and governs the
        privacy of those who use it. The purpose of this policy is to explain to
        you how we control, process, handle and protect your personal
        information while browsing or using this website, including your rights
        under current laws and regulations. If you do not agree to the following
        policy you may wish to cease viewing / using this website.
      </p>

      <h2>Policy key definitions:</h2>
      <ul>
        <li>
          “I”, “our”, “us”, or “we” refer to the business, pass swiftly Ltd.
        </li>
        <li>“You”, “the user” refers to the person(s) using this website.</li>
        <li>GDPR means General Data Protection Act.</li>
        <li>PECR means Privacy & Electronic Communications Regulation.</li>
        <li>ICO means Information Commissioner’s Office.</li>
        <li>Cookies mean small files stored on a user’s computer or device.</li>
      </ul>

      <h2>Processing of your personal data</h2>
      <p>
        Under the GDPR (General Data Protection Regulation) we control and / or
        process any personal information about you electronically using the
        following lawful bases.
      </p>
      <ul>
        <li>
          <strong>Lawful basis: Consent</strong>
          <br />
          The reason we use this basis: The administration of your driving
          course.
          <br />
          We process your information in the following ways: To find you a
          driving instructor and book you a driving tests.
          <br />
          Data retention period: We will continue to process your information
          under this basis until you withdraw consent or it is determined your
          consent no longer exists.
          <br />
          Sharing your information: We do not share your information with third
          parties.
        </li>
        <li>
          <strong>Lawful basis: Contract</strong>
          <br />
          The reason we use this basis: The administration of your driving
          course.
          <br />
          We process your information in the following ways: To find you a
          driving instructor and book you a driving tests.
          <br />
          Data retention period: We shall continue to process your information
          until the contract between us ends or is terminated under any contract
          terms.
          <br />
          Sharing your information: We do not share your information with third
          parties.
        </li>
      </ul>
      <p>
        If, as determined by us, the lawful basis upon which we process your
        personal information changes, we will notify you about the change and
        any new lawful basis to be used if required. We shall stop processing
        your personal information if the lawful basis used is no longer
        relevant.
      </p>

      <h2>Your individual rights</h2>
      <p>
        Under the GDPR your rights are as follows. You can read more about your
        rights in details here;
      </p>
      <ul>
        <li>the right to be informed;</li>
        <li>the right of access;</li>
        <li>the right to rectification;</li>
        <li>the right to erasure;</li>
        <li>the right to restrict processing;</li>
        <li>the right to data portability;</li>
        <li>the right to object; and</li>
        <li>
          the right not to be subject to automated decision-making including
          profiling.
        </li>
      </ul>
      <p>
        You also have the right to complain to the ICO [www.ico.org.uk] if you
        feel there is a problem with the way we are handling your data. We
        handle subject access requests in accordance with the GDPR.
      </p>

      <h2>Internet cookies</h2>
      <p>
        We use cookies on this website to provide you with a better user
        experience. We do this by placing a small text file on your device /
        computer hard drive to track how you use the website, to record or log
        whether you have seen particular messages that we display, to keep you
        logged into the website where applicable, to display relevant adverts or
        content, referred you to a third party website.
      </p>
      <p>
        Some cookies are required to enjoy and use the full functionality of
        this website. We use a cookie control system which allows you to accept
        the use of cookies, and control which cookies are saved to your device /
        computer. Some cookies will be saved for specific time periods, where
        others may last indefinitely. Your web browser should provide you with
        the controls to manage and delete cookies from your device, please see
        your web browser options.
      </p>

      <h2>Data security and protection</h2>
      <p>
        We ensure the security of any personal information we hold by using
        secure data storage technologies and precise procedures in how we store,
        access and manage that information. Our methods meet the GDPR compliance
        requirement.
      </p>

      <h2>Transparent Privacy Explanations</h2>
      <p>
        We have provided some further explanations about user privacy and the
        way we use this website to help promote a transparent and honest user
        privacy methodology.
      </p>

      <h2>Sponsored links, affiliate tracking & commissions</h2>
      <p>
        Our website may contain adverts, sponsored and affiliate links on some
        pages. These are typically served through our advertising partners;
        Google Ad sense, eBay Partner Network, Amazon Affiliates, or are self
        served through our own means. We only use trusted advertising partners
        who each have high standards of user privacy and security. However we do
        not control the actual adverts seen / displayed by our advertising
        partners.
      </p>
      <p>
        Clickable sponsored or affiliate links may be displayed as a website URL
        or as a titled text link. Clicking on any adverts, sponsored or
        affiliate links may track your actions by using a cookie saved to your
        device. You can read more about cookies on this website above. Your
        actions are usually recorded as a referral from our website by this
        cookie. In most cases we earn a very small commission from the
        advertiser or advertising partner, at no cost to you, whether you make a
        purchase on their website or not.
      </p>
      <p>
        We use advertising partners in these ways to help generate an income
        from the website, which allows us to continue our work and provide you
        with the best overall experience and valued information. If you have any
        concerns about this we suggest you do not click on any adverts,
        sponsored or affiliate links found throughout the website.
      </p>

      <h2>Email marketing messages & subscription</h2>
      <p>
        Under the GDPR we use the consent lawful basis for anyone subscribing to
        our newsletter or marketing mailing list. We only collect certain data
        about you, as detailed in the “Processing of your personal date” above.
        Any email marketing messages we send are done so through an EMS, email
        marketing service provider. An EMS is a third party service provider of
        software / applications that allow marketers to send out email marketing
        campaigns to a list of users.
      </p>
      <p>
        Email marketing messages that we send may contain tracking beacons /
        tracked clickable links or similar server technologies in order to track
        subscriber activity within email marketing messages. Where used, such
        marketing messages may record a range of data such as; times, dates, I.P
        addresses, opens, clicks, forwards, geographic and demographic data.
        Such data, within its limitations will show the activity each subscriber
        made for that email campaign.
      </p>
      <p>
        Any email marketing messages we send are in accordance with the GDPR and
        the PECR. We provide you with an easy method to withdraw your consent
        (unsubscribe) or manage your preferences / the information we hold about
        you at any time. See any marketing messages for instructions on how to
        unsubscribe or manage your preferences; you can also unsubscribe from
        all Mail Chimp lists, by following this link, otherwise contact the EMS
        provider.
      </p>
      <p>
        We hold the following information about you within our system (if
        given);
      </p>
      <ul>
        <li>Email address</li>
        <li>I.P address</li>
        <li>
          As well as any other data which you supplied us, with when completing
          any forms
        </li>
      </ul>

      <h2>Resources & further information</h2>
      <ul>
        <li>Overview of the GDPR – General Data Protection Regulation</li>
        <li>Data Protection Act 1998</li>
        <li>Privacy and Electronic Communications Regulations 2003</li>
        <li>The Guide to the PECR 2003</li>
        <li>Twitter Privacy Policy</li>
        <li>Facebook Privacy Policy</li>
        <li>Google Privacy Policy</li>
        <li>Linkedin Privacy Policy</li>
        <li>Mailchimp Privacy Policy</li>
      </ul>
    </div>
  );
};

export default PrivacyPolicy;
