import React, { useEffect, useState } from "react";
import "../styles/InstructorProfile.css";
import BasicInputFields from "./misc/BasicInputFields";
import { getUser } from "../services/users";
import { Can } from "../configs/Ability-context";
import { useLocation } from "react-router-dom";
import { Dialog } from "primereact/dialog";
const InstructorProfile = () => {
  const deobfuscate = (value) => atob(value); // Base64 decode
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const Userid = queryParams.get("userId");
  const readOnlyParam = queryParams.get("readonly");
  const [readOnly, setReadOnly] = useState(
    deobfuscate(readOnlyParam) === "true"
  ); // Decode and check
  const [selectedImage, setSelectedImage] = useState(null);
  const [isImageModalVisible, setIsImageModalVisible] = useState(false);
  const [editingDocument, setEditingDocument] = useState({
    adi_badge_front: false,
    adi_badge_back: false,
    selfie_holding_adi: false,
  });
  const formatDateForBadgeExpiry = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
  };
  // // const readOnly = queryParams.get("readOnly");
  // const readOnly = queryParams.get("readonly") === "true";
  const [InstructorDetails, setInstructorDetails] = useState({
    name: "",
    email: "",
    phone: "",
    gender: "",
    address: "",
    postcode: "",
    hourly_rate: "",
    transmission: "",
    adi_number: "",
    adi_badge_expiry: "",
    adi_badge_front: "", //(attachment)
    adi_badge_back: "", //(attachment)
    selfie_holding_adi: "", //  (attachment)
    car_make: "",
    car_model: "",
    car_registration_year: "",
    bank_details: "", // (Sort Code & Account No)
    decline_hours: "",
  });
  const userId = Userid || JSON.parse(localStorage.getItem("ui"));
  useEffect(() => {
    const fetchData = async () => {
      const { data } = await getUser(userId);
      const {
        name,
        email,
        phone,
        gender,
        address,
        postcode,
        hourly_rate,
        transmission,
        adi_number,
        adi_badge_expiry,
        adi_badge_front,
        adi_badge_back,
        selfie_holding_adi,
        car_make,
        car_model,
        car_registration_year,
        bank_details,
        decline_hours,
      } = data;
      console.log("data", data);

      setInstructorDetails({
        name,
        email,
        phone,
        gender,
        address,
        postcode,
        hourly_rate: hourly_rate,
        transmission: transmission,
        adi_number: adi_number,
        adi_badge_expiry: adi_badge_expiry,
        adi_badge_front: adi_badge_front, //(attachment)
        adi_badge_back: adi_badge_back, //(attachment)
        selfie_holding_adi: selfie_holding_adi, //  (attachment)
        car_make: car_make,
        car_model: car_model,
        car_registration_year: car_registration_year,
        bank_details: bank_details,
        decline_hours: decline_hours,
      });
    };
    if (userId) fetchData();
  }, [userId]);
  const handleChange = (e) => {
    console.log(readOnly === true);

    if (readOnly) {
      return;
    }
    setInstructorDetails({
      ...InstructorDetails,
      [e.target.name]: e.target.value,
    });
    console.log({ [e.target.name]: e.target.value });
    console.log(InstructorDetails);
  };
  const handleFileChange = (e) => {
    console.log("File change event triggered"); // Log when the function is called
    const file = e.target.files[0];
    const name = e.target.name;

    if (file) {
      console.log(`File selected for ${name}:`, file); // Log the file details
      setInstructorDetails((prevDetails) => ({
        ...prevDetails,
        [name]: file,
      }));
    } else {
      console.log(`No file selected for ${name}`);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("UpdatedUser", JSON.stringify(InstructorDetails));

    const fields = ["adi_badge_front", "adi_badge_back", "selfie_holding_adi"];
    fields.forEach((field) => {
      if (InstructorDetails[field]) {
        formData.append(field, InstructorDetails[field]);
      }
    });

    const response = await fetch(`/api/update-instructor/${userId}`, {
      method: "PUT",
      body: formData,
    });

    const json = await response.json();
    console.log(json);
  };

  const handleImageClick = (imagePath) => {
    const imageUrl = imagePath.replace(
      "C:\\Users\\GNG\\Documents\\GitHub\\pass-swiftly\\server\\express\\uploads\\images\\",
      "http://localhost:8003/uploads/" // Replace with your actual server URL and path
    );
    console.log(imageUrl);

    setSelectedImage(imageUrl);
    setIsImageModalVisible(true);
  };

  const renderDocumentInputOrImage = (documentPath, inputName, label) => {
    const hasDocument = documentPath && documentPath.trim() !== "";

    return (
      <div className="instructor-input-column ">
        <label className="instructor-form-input-label">{label}</label>
        {hasDocument && !editingDocument[inputName] ? (
          <>
            <p
              onClick={() => handleImageClick(documentPath)}
              className="cursor-pointer text-decoration-underline"
              style={{ color: "navy" }}
            >
              View {label}
            </p>
            {!readOnly && (
              <button
                type="button"
                onClick={() =>
                  setEditingDocument((prev) => ({
                    ...prev,
                    [inputName]: true,
                  }))
                }
              >
                Edit {label}
              </button>
            )}
          </>
        ) : (
          <>
            <input
              className="instructor-form-input"
              type="file"
              name={inputName}
              placeholder={inputName}
              onChange={handleFileChange}
            />
            <button
              onClick={() => {
                setEditingDocument((prev) => ({ ...prev, [inputName]: false }));
              }}
            >
              Exit Edit
            </button>
          </>
        )}
      </div>
    );
  };

  const handleCloseImageModal = () => {
    setSelectedImage(null);
    setIsImageModalVisible(false);
  };

  return (
    <Can I="manage" a="instructorProfile">
      <div
        className="container p-3"
        id="main-instructor-profile-container"
        enctype="multipart/form-data"
      >
        <h2 className="text-center">InstructorProfile</h2>
        <form onSubmit={handleSubmit}>
          <BasicInputFields
            handleChange={handleChange}
            details={InstructorDetails}
          />
          <div className=" instructor-input-row">
            <div className="instructor-input-column">
              <label className="instructor-form-input-label">Hourly Rate</label>
              <input
                className="instructor-form-input"
                name="hourly_rate"
                type="text"
                placeholder="Hourly Rate"
                value={InstructorDetails.hourly_rate || ""}
                onChange={handleChange}
              />
            </div>
            <div className="instructor-input-column">
              <label className="instructor-form-input-label">
                Transmission
              </label>
              <select
                className="instructor-form-input"
                name="transmission"
                value={InstructorDetails.transmission || ""}
                onChange={handleChange}
                required
              >
                <option value="" disabled hidden>
                  Select Transmission
                </option>
                <option value="manual">Manual</option>
                <option value="automatic">Automatic</option>
                <option value="manual&automatic">Manual and Auto</option>
                {/* <option value="both">Manual and Auto</option> */}
              </select>
            </div>
            <div className="instructor-input-column">
              <label className="instructor-form-input-label">ADI Number</label>
              <input
                className="instructor-form-input"
                type="text"
                name="adi_number"
                placeholder="ADI Number"
                value={InstructorDetails.adi_number || ""}
                onChange={handleChange}
              />
            </div>
            <div className="instructor-input-column">
              <label className="instructor-form-input-label">
                ADI Badge Expiry
              </label>
              <input
                className="instructor-form-input"
                type="date"
                name="adi_badge_expiry"
                placeholder="ADI Badge Expiry"
                value={
                  formatDateForBadgeExpiry(
                    InstructorDetails.adi_badge_expiry
                  ) || ""
                }
                onChange={handleChange}
              />
            </div>
          </div>
          {/* end of First Row */}
          <div className="instructor-input-row">
            {renderDocumentInputOrImage(
              InstructorDetails.adi_badge_front,
              "adi_badge_front",
              "ADI Badge Front"
            )}
            {renderDocumentInputOrImage(
              InstructorDetails.adi_badge_back,
              "adi_badge_back",
              "ADI Badge Back"
            )}
            {renderDocumentInputOrImage(
              InstructorDetails.selfie_holding_adi,
              "selfie_holding_adi",
              "Selfie Holding ADI"
            )}
            {/* <div className="instructor-input-column">
              <label className="instructor-form-input-label">
                ADI Badge Front
              </label>
              <input
                className="instructor-form-input"
                type="file"
                name="adi_badge_front"
                placeholder="adi_badge_front"
                // value={InstructorDetails.adi_badge_front || ""}
                onChange={handleFileChange}
              />
            </div>
            <div className="instructor-input-column">
              <label className="instructor-form-input-label">
                ADI Badge Back
              </label>
              <input
                className="instructor-form-input"
                type="file"
                name="adi_badge_back"
                placeholder="adi_badge_back"
                // value={InstructorDetails.adi_badge_back || ""}
                onChange={handleFileChange}
              />
            </div>
            <div className="instructor-input-column">
              <label className="instructor-form-input-label">
                Selfie Holding ADI
              </label>
              <input
                className="instructor-form-input"
                name="selfie_holding_adi"
                type="file"
                placeholder="selfie_holding_adi"
                // value={(InstructorDetails.selfie_holding_adi) || ""}
                onChange={handleFileChange}
              />
            </div> */}
          </div>
          {/* end of third row */}
          <div className="instructor-input-row">
            <div className="instructor-input-column">
              <label className="instructor-form-input-label">Car Make</label>
              <input
                className="instructor-form-input"
                type="text"
                name="car_make"
                placeholder="car_make"
                value={InstructorDetails.car_make || ""}
                onChange={handleChange}
              />
            </div>
            <div className="instructor-input-column">
              <label className="instructor-form-input-label">Car Model</label>
              <input
                className="instructor-form-input"
                type="text"
                name="car_model"
                placeholder="CarModel"
                value={InstructorDetails.car_model || ""}
                onChange={handleChange}
              />
            </div>
            <div className="instructor-input-column">
              <label className="instructor-form-input-label">
                Car Registeration Year
              </label>
              <input
                className="instructor-form-input"
                type="text"
                name="car_registration_year"
                placeholder="Car Registeration Year"
                value={InstructorDetails.car_registration_year || ""}
                onChange={handleChange}
              />
            </div>
            <div className="instructor-input-column">
              <label className="instructor-form-input-label">
                Bank Details
              </label>
              <input
                className="instructor-form-input"
                type="text"
                name="bank_details"
                placeholder="Bank Details"
                value={InstructorDetails.bank_details || ""}
                onChange={handleChange}
              />
            </div>
            <div className="instructor-input-column">
              <label className="instructor-form-input-label">
                Min Hours For Decline
              </label>
              <input
                className="instructor-form-input"
                type="number"
                name="decline_hours"
                placeholder="Min Hours For Decline"
                value={InstructorDetails.decline_hours || ""}
                onChange={handleChange}
              />
            </div>
          </div>

          {readOnly !== true && (
            <button
              className="instructor-profile-submit-button float-end"
              type="submit"
            >
              SUBMIT
            </button>
          )}

          {/* <button type="submit">Update</button> */}
        </form>
      </div>
      <Dialog
        header="View Image"
        visible={isImageModalVisible}
        onHide={handleCloseImageModal}
        modal
      >
        <img
          src={selectedImage}
          alt="Instructor Document"
          style={{ width: "100%" }}
        />
      </Dialog>
    </Can>
  );
};

export default InstructorProfile;
