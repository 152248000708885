import React, { useEffect, useState } from "react";
import "../styles/InstructorProfile.css";
import BasicInputFields from "./misc/BasicInputFields";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { InputText } from "primereact/inputtext";
import { useAuth } from "../contexts/AuthContext";
import { getUser, updateUser } from "../services/users";
import { Can } from "../configs/Ability-context";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

// import axios from 'axios';

const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
const StudentAvailability = ({ availability, setAvailability, readOnly }) => {
  // const [availability, setAvailability] = useState(
  //   daysOfWeek.map((day) => ({
  //     day,
  //     available: false,
  //     from: "15:00",
  //     to: "20:00",
  //   }))
  // );

  const handleCheckboxChange = (index) => {
    if (readOnly === true) {
      return;
    }
    // const newAvailability = availability.filter((availability) => {
    //   return availability.NotAvailable === false;
    // });
    const newAvailability = [...availability];
    newAvailability[index].available = !newAvailability[index].available;

    setAvailability(newAvailability);
  };

  const handleTimeChange = (index, field, value) => {
    if (readOnly === true) {
      return;
    }
    const newAvailability = [...availability];
    newAvailability[index][field] = value;
    setAvailability(newAvailability);
  };

  const handleSubmit = async () => {
    try {
      //   await axios.post('/api/availability', { availability });
      //   await fetch("/api/availability", { availability });
      console.log(availability);
      alert("Availability saved successfully!");
    } catch (error) {
      console.error("Error saving availability", error);
      alert("Failed to save availability");
    }
  };

  return (
    <div>
      <p className="fs-5">Select Your Availability</p>
      {availability.map((slot, index) => (
        <div key={index} style={{ marginBottom: "10px" }}>
          <label>
            <input
              type="checkbox"
              className="instructor-input-field mx-2"
              checked={slot.available}
              onChange={() => handleCheckboxChange(index)}
            />
            {slot.day}
            {/* {slot.day} NotAvailable */}
          </label>
          <div className=" ">
            from:
            <input
              type="time"
              className="instructor-input-field p-2 mx-2"
              value={slot.from}
              disabled={!slot.available}
              onChange={(e) => handleTimeChange(index, "from", e.target.value)}
            />
            to:
            <input
              type="time"
              className="instructor-input-field p-2 mx-2"
              value={slot.to}
              disabled={!slot.available}
              onChange={(e) => handleTimeChange(index, "to", e.target.value)}
            />
          </div>
        </div>
      ))}
      {/* <button onClick={handleSubmit}>Save Availability</button> */}
    </div>
  );
};

// export default StudentAvailability;

// const daysOfWeek = [
//   { name: "Monday", code: "MO" },
//   { name: "Tuesday", code: "TU" },
//   { name: "Wednesday", code: "WE" },
//   { name: "Thursday", code: "TH" },
//   { name: "Friday", code: "FR" },
//   { name: "Saturday", code: "SA" },
//   { name: "Sunday", code: "SU" },
// ];

// const timeSlots = [
//   "08:00 AM - 09:00 AM",
//   "09:00 AM - 10:00 AM",
//   "10:00 AM - 11:00 AM",
//   "11:00 AM - 12:00 PM",
//   "12:00 PM - 01:00 PM",
//   "01:00 PM - 02:00 PM",
//   "02:00 PM - 03:00 PM",
//   "03:00 PM - 04:00 PM",
//   "04:00 PM - 05:00 PM",
// ];

// const TimeSlotSelection = () => {
//   const [selectedDays, setSelectedDays] = useState([]);
//   const [selectedTimeSlots, setSelectedTimeSlots] = useState({});

//   const handleDayChange = (e) => {
//     setSelectedDays(e.value);
//   };

//   const handleTimeSlotChange = (day, e) => {
//     setSelectedTimeSlots({ ...selectedTimeSlots, [day]: e.value });
//   };

//   return (
//     <div className="timeslot-selection">
//       {/* <h3>Select available Time Slots for the Week</h3> */}
//       <div className="instructor-input-row">
//         <MultiSelect
//           value={selectedDays}
//           options={daysOfWeek}
//           onChange={handleDayChange}
//           optionLabel="name"
//           placeholder="Select Days"
//           display="chip"
//           className="m-2"
//         />
//         {/* <div className="instructor-input-row"> */}
//         {selectedDays.map((day) => (
//           <div key={day.code} className="instructor-input-column">
//             {/* <label className="instructor-form-input-label">Hourly Rate</label> */}
//             <label className="instructor-form-input-label"> {day.name}</label>
//             <MultiSelect
//               value={selectedTimeSlots[day.name] || []}
//               options={timeSlots}
//               onChange={(e) => handleTimeSlotChange(day.name, e)}
//               placeholder={`Select Time Slots for ${day.name}`}
//               display="chip"
//               className="m-2 w-50"
//             />
//           </div>
//           /* <div key={day.code} className="day-timeslot w-75">
//               <h4>{day.name}</h4>
//               <MultiSelect
//                 value={selectedTimeSlots[day.name] || []}
//                 options={timeSlots}
//                 onChange={(e) => handleTimeSlotChange(day.name, e)}
//                 placeholder={`Select Time Slots for ${day.name}`}
//                 display="chip"
//                 className="m-2 w-50"
//               />
//             </div> */
//         ))}
//       </div>
//     </div>
//     // </div>
//   );
// };

const StudentProfile = () => {
  const deobfuscate = (value) => atob(value); // Base64 decode
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const Userid = queryParams.get("userId");
  const readOnlyParam = queryParams.get("readonly");
  const [readOnly, setReadOnly] = useState(
    deobfuscate(readOnlyParam) === "true"
  ); // Decode and check
  // const [initialdetails, setInitialDetails] = useState({
  //   name: "",
  //   email: "",
  //   phone: "",
  //   postcode: "",
  // });
  const [studentDetails, setStudentDetails] = useState({
    name: "",
    email: "",
    phone: "",
    gender: "",
    address: "",
    postcode: "",
    provisional_license_number: "",
  });
  const [weekAvailability, setWeekAvailability] = useState(
    daysOfWeek.map((day) => ({
      day,
      available: false,
      from: "15:00",
      to: "20:00",
    }))
  );
  const userId = Userid || JSON.parse(localStorage.getItem("ui"));
  const handleChange = (e) => {
    if (readOnly === true) {
      return;
    }
    setStudentDetails({
      ...studentDetails,
      [e.target.name]: e.target.value,
    });
    console.log({ [e.target.name]: e.target.value });
    console.log(studentDetails);
  };
  const validateLicenseNumber = (licenseNumber) => {
    // const regex = /^[A-Z0-9]{16}$/; // Adjust based on expected format
    const regex = /^[A-Z]{2}\d{6}[A-Z0-9]{8}$/;
    return regex.test(licenseNumber);
  };
  const handleUpdateStudentProfile = async (e) => {
    e.preventDefault();
    console.log(studentDetails);
    console.log(weekAvailability);
    if (!validateLicenseNumber(studentDetails.provisional_license_number)) {
      toast.error("Invalid Provisional Licence Number");
      return;
    }
    const response = await updateUser(
      userId,
      studentDetails,
      weekAvailability
    ).then((res) => {
      console.log(res);
      // if (res.status === 200) {
      if (res.success === true) {
        alert("Profile updated successfully");
      } else {
        alert("Profile update failed");
      }
    });
    console.log(response);
  };
  // useEffect(() => {
  //   const fetchData = async () => {
  //     // if (APO_Permission === "update") {
  //     // user =
  //     //   user && user != 1 && ability.can("update", "users") && isRouteMatched
  //     //     ? user
  //     //     : 0;

  //     const { data } = await getUser(userId);
  //     const { email } = data;
  //     // const { name, email, phone, postcode } = data;
  //     console.log("data in edit permission", data);
  //     setUserEmail(email);
  //     // setUserEmail({
  //     //   // ...studentDetails,
  //     //   // name,
  //     //   email,
  //     //   // phone,
  //     //   // postcode,
  //     // });
  //     // setState((prevState) => ({
  //     //   ...prevState,
  //     //   name,
  //     //   email,
  //     //   phone,
  //     //   postcode,
  //     //   role,
  //     //   isLoading: false,
  //     // }));
  //     // }
  //   };
  //   userId && fetchData();
  // }, []);
  useEffect(() => {
    const fetchData = async () => {
      const { data } = await getUser(userId);
      const {
        name,
        email,
        phone,
        gender,
        address,
        postcode,
        availability,
        provisional_license_number,
      } = data;
      setStudentDetails({
        name,
        email,
        phone,
        gender,
        address,
        postcode,
        provisional_license_number,
      });
      setWeekAvailability(availability || weekAvailability); // Ensure availability is handled correctly
    };
    if (userId) fetchData();
  }, [userId]);
  // const { userId } = useAuth();
  return (
    <Can I="manage" a="studentProfile">
      <div className="container p-3" id="main-instructor-profile-container">
        <h2 className="text-center">Student Profile</h2>
        <form>
          <BasicInputFields
            // initialdetails={initialdetails}
            handleChange={handleChange}
            details={studentDetails}
          />
          <div className="instructor-input-column col-md-3">
            <label className="instructor-form-input-label">
              Provisional License Number{" "}
            </label>
            <input
              className="instructor-form-input"
              name="provisional_license_number"
              type="text"
              maxlength="16"
              pattern="[A-Za-z0-9]{16}"
              placeholder="Enter Provisional License Number"
              value={studentDetails.provisional_license_number || ""}
              onChange={handleChange}
            />
          </div>
          <StudentAvailability
            readOnly={readOnly}
            availability={weekAvailability}
            setAvailability={setWeekAvailability}
          />

          {readOnly !== true && (
            <button
              className="instructor-profile-submit-button"
              onClick={handleUpdateStudentProfile}
              // style={{
              //   backgroundColor: "black",
              //   color: "white",
              //   borderRadius: "20px",
              // }}
            >
              SUBMIT
            </button>
          )}

          {/* <button type="submit">Update</button> */}
        </form>
      </div>
    </Can>
  );
};

export default StudentProfile;
