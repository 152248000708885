import React from "react";
import Sidebar from "../Components/Sidebar";
import { Outlet } from "react-router-dom";
import LoggedInHeader from "../Components/LoggedInHeader";

const MyAccount = ({
  accountApproved,
  collapsed,
  handleLogOut,
  handleToggleSidebar,
}) => {
  const userId = localStorage.getItem("userId");
  const userRole = localStorage.getItem("userRole");
  return (
    <div>
      <LoggedInHeader handleLogOut={handleLogOut} />
      <div className="layout">
        {userRole === `"admin"` || accountApproved ? (
          <Sidebar
            userId={userId}
            userRole={userRole}
            handleLogOut={handleLogOut}
            collapsed={collapsed}
            onToggleSidebar={handleToggleSidebar}
          />
        ) : null}
        <main className="layout-content">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default MyAccount;
