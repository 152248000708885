import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"; // Hook to get URL params
import { Card } from "primereact/card";

const PostDetail = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await axios.get(`/api/wp/${slug}`);
        console.log(response);
        setPost(response.data[0]);
      } catch (error) {
        console.error("Error fetching the post:", error);
      }
    };
    fetchPost();
  }, [slug]);

  return (
    <div className="container mt-5">
      {post ? (
        <Card
          title={
            <div
              dangerouslySetInnerHTML={{ __html: post.title.rendered }}
            ></div>
          }
        >
          <div
            style={{ fontFamily: "sans-serif" }}
            className="content"
            dangerouslySetInnerHTML={{ __html: post.content.rendered }}
          ></div>
        </Card>
      ) : (
        <p>Loading post...</p>
      )}
    </div>
  );
};

export default PostDetail;
