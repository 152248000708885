export const createUser = async ({ user }) => {
  try {
    const response = await fetch("/api/create-user", {
      method: "POST",
      body: JSON.stringify({ user }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok " + response.statusText);
    }
    const data = await response.json();
    // console.log(data);
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error; // Ensure the error is propagated
  }
};
export const signUpUser = async ({ newUser }) => {
  try {
    const options = {
      method: "POST",
      body: JSON.stringify({ newUser }),
      headers: { "Content-Type": "application/json" },
    };
    const res = await fetch("/api/users/sign-up", options);
    const json = await res.json();
    // console.log(json);
    return json;
  } catch (error) {
    console.log(error);
  }
};
export const getUsers = async () => {
  try {
    const res = await fetch(`/api/users`);
    const json = await res.json();
    // console.log(json);
    return json;
  } catch (error) {
    console.log(error);
  }
};
export const getUser = async (id) => {
  try {
    const res = await fetch(`/api/users/${id}`);
    const json = await res.json();
    // console.log(json);
    return json;
  } catch (error) {
    console.log(error);
  }
};

export const getInstructorLearners = async (instructorId) => {
  try {
    const res = await fetch(`/api/learners/instructor/${instructorId}`);
    const json = await res.json();
    // console.log(json);
    return json;
  } catch (error) {
    console.log(error);
  }
};
export const addPrivateLearner = async (id, learner) => {
  try {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ learner }),
    };
    const res = await fetch(`/api/private/instructor-learner/${id}`, options);
    const json = await res.json();
    // console.log(json);
    return json;
  } catch (error) {
    console.log(error);
  }
};
export const updateUser = async (id, updatedUser, availability) => {
  const {
    name,
    email,
    phone,
    gender,
    address,
    postcode,
    HourlyRate,
    transmission,
    ADINumber,
    ADIBadgeExpiry,
    ADIBadgeFront, //(attachment)
    ADIBadgeBack, //(attachment)
    SelfieholdingpicturepartofADIlicence, //  (attachment)
    CarMake,
    Model,
    CarRegistrationYear,
    BankDetails,
    provisional_license_number,
  } = updatedUser;
  const UpdatedUser = {
    name: name,
    email: email,
    phone: phone,
    gender: gender,
    address: address,
    postcode: postcode,
    hourly_rate: HourlyRate,
    transmission: transmission,
    adi_number: ADINumber,
    adi_badge_expiry: ADIBadgeExpiry,
    adi_badge_front: ADIBadgeFront,
    adi_badge_back: ADIBadgeBack,
    selfie_holding_adi: SelfieholdingpicturepartofADIlicence,
    car_make: CarMake,
    car_model: Model,
    car_registration_year: CarRegistrationYear,
    bank_details: BankDetails,
    availability: availability,
    provisional_license_number: provisional_license_number,
  };
  try {
    console.log(
      name,
      email,
      phone,
      gender,
      address,
      postcode,
      HourlyRate,
      transmission,
      ADINumber,
      ADIBadgeExpiry,
      ADIBadgeFront, //(attachment)
      ADIBadgeBack, //(attachment)
      SelfieholdingpicturepartofADIlicence, //  (attachment)
      CarMake,
      Model,
      CarRegistrationYear,
      BankDetails,
      provisional_license_number
    );
    const options = {
      method: "PUT",
      body: JSON.stringify({ UpdatedUser }),
      headers: { "Content-Type": "application/json" },
    };
    const res = await fetch(`/api/update-student/${id}`, options);
    const json = await res.json();
    // console.log(json);
    return json;
  } catch (error) {
    console.log(error);
  }
};
// export const updateUser = async (id, updatedUser, type) => {
//   try {
//     const options = {
//       method: "PUT",
//       body: JSON.stringify({ updatedUser }),
//       headers: { "Content-Type": "application/json" },
//     };
//     const res = await fetch(`/api/users/${id}?type=${type}`, options);
//     const json = await res.json();
//     console.log(json);
//     return json;
//   } catch (error) {
//     console.log(error);
//   }
// };
export const getUsersWithData = async () => {
  try {
    const res = await fetch(`/api/users/data`);
    const json = await res.json();
    // console.log(json);
    return json;
  } catch (error) {
    console.log(error);
  }
};
export const verifyUserEmail = async (token) => {
  try {
    const endpoint = `/api/verify_email?token=${encodeURIComponent(token)}`;
    const res = await fetch(endpoint);
    const json = await res.json();
    // console.log(res)
    return json;
  } catch (error) {
    console.log(error);
  }
};
export const resendVerificationEmail = async (user_id) => {
  try {
    const endpoint = `/api/sellers/resend_verification_email?user_id=${user_id}`;
    const res = await fetch(endpoint);
    const json = await res.json();
    // console.log(res)
    return json;
  } catch (error) {
    console.log(error);
  }
};
export const verifyResetPasswordToken = async (token) => {
  try {
    const endpoint = `/verifypasswordtoken?token=${encodeURIComponent(token)}`;
    const res = await fetch(endpoint);
    const json = await res.json();
    return json;
  } catch (error) {
    console.log(error);
  }
};

export const moveUserToTrash = async (userId) => {
  try {
    const options = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    };
    const res = await fetch(`/api/users/trash/${userId}`, options);
    const json = await res.json();
    // console.log(json);
    return json;
  } catch (error) {
    console.log(error);
  }
};

export const deleteUser = async (userId) => {
  try {
    const options = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    };
    const res = await fetch(`/api/users/${userId}`, options);
    const json = await res.json();
    // console.log(json);
    return json;
  } catch (error) {
    console.log(error);
  }
};
export const deleteAllUsersInTrash = async () => {
  try {
    const options = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    };
    const res = await fetch(`/api/users/delete/all`, options);
    const json = await res.json();
    // console.log(json);
    return json;
  } catch (err) {
    console.log(err);
  }
};
export const restoreUser = async (userId) => {
  try {
    const res = await fetch(`/api/users/restore/${userId}`);
    const json = await res.json();
    // console.log(json);
    return json;
  } catch (error) {
    console.log(error);
  }
};
export const restoreAllUsersFromTrash = async () => {
  try {
    const res = await fetch(`/api/users/restore/all`);
    const json = await res.json();
    // console.log(json);
    return json;
  } catch (error) {
    console.log(error);
  }
};
export const getUsersTrash = async () => {
  try {
    const res = await fetch(`/api/user/trash`);
    const json = await res.json();
    // console.log(json);
    return json;
  } catch (error) {
    console.log(error);
  }
};
