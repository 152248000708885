import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, useParams, useMatch } from "react-router-dom";
import { MDBRow, MDBCol } from "mdbreact";
import { getPermission, updatePermission } from "../services/permissions";
import { AbilityContext, Can } from "../configs/Ability-context";
import { ActionButton, Input } from "../Components/UI/Customs";
import LoaderModal from "../Components/custom/LoaderModal";
import PasswordConfirmationModal from "../Components/misc/PasswordConfirmationModal";
import { getUser, updateUser } from "../services/users";

let postcodeField, phoneField, emailField, nameField, roleField;
const INITIAL_STATE = {
  name: "",
  email: "",
  phone: "",
  postcode: "",
  role: "",
};

const EditUser = (props) => {
  const [
    {
      name,
      email,
      phone,
      postcode,
      role,
      validationMessage,
      isLoading,
      isConfirmingPassword,
    },
    setState,
  ] = useState({
    ...INITIAL_STATE,
    validationMessage: "",
    isLoading: false,
    isConfirmingPassword: false,
  });
  const ability = useContext(AbilityContext);

  let permissionForm = useRef(null);
  let { APO_Permission } = props;
  let { user } = useParams();
  let navigate = useNavigate();
  const isRouteMatched = useMatch("/admin/users/edit/:user");

  useEffect(() => {
    const fetchData = async () => {
      // if (APO_Permission === "update") {
      user =
        user && user != 1 && ability.can("update", "users") && isRouteMatched
          ? user
          : 0;

      changeValue("isLoading", true);
      const { data } = user != 1 && (await getUser(user));
      const { name, email, phone, postcode, role } = data;
      console.log("data in edit permission", data);
      setState((prevState) => ({
        ...prevState,
        name,
        email,
        phone,
        postcode,
        role,
        isLoading: false,
      }));
      // }
    };
    fetchData();
  }, []);

  const changeValue = (name, value) =>
    setState((prevState) => ({ ...prevState, [name]: value }));

  const handleInput = (e) => {
    changeValue(e.target.name, e.target.value);
    // let { name } = e.target;
    // name === "slug"
    //   ? changeValue("slug", e.target.value)
    //   : changeValue("enabled", e.target.checked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let form = permissionForm.current;

    if (!form.checkValidity()) {
      form.classList.add("was-validated");
      return;
    }
    changeValue("isConfirmingPassword", true);
  };

  const handleUpdate = async () => {
    let updatedUser = { name, email, phone, postcode, role };
    const { success, message } = await updateUser(user, updatedUser);
    changeValue("validationMessage", message);
    // !success && slugField.focus();
    setTimeout(() => {
      success && navigate("/users");
    }, 1500);
  };

  return (
    <Can I={"update"} a="permissions">
      {/* {JSON.stringify(user)} */}
      <div className="screen-center h-100vh container">
        <form
          ref={permissionForm}
          className="m-0 p-0 w-100 w-sm-75 w-md-50 text-center color-tertiary mx-auto"
          onSubmit={handleSubmit}
          noValidate
        >
          <h5 className="font-weight-bold mb-3 mb-sm-4">{"Edit permission"}</h5>
          <MDBRow center className="m-0 p-0">
            <MDBCol middle sm="10" className="m-0">
              <Input label="Name" name={`name`} value={name} disabled />
              <Input label="Role" name={`role.name`} value={role.name} />
              <Input label="Email" name={`email`} value={email} disabled />
              <Input
                label="Phone"
                name={`phone`}
                value={phone}
                handleInput={handleInput}
                // setRef={(ref) => (slugField = ref)}
                required
                invalidMessage={`Please enter slug for this permission`}
                disabled
              />
              <Input
                label="PostCode"
                name={`postcode`}
                value={postcode}
                handleInput={handleInput}
                setRef={(ref) => (postcodeField = ref)}
                required
                invalidMessage={`Please enter slug for this permission`}
              />
              {/* <div className="custom-control custom-checkbox text-left mb-3">
                <input
                  type="checkbox"
                  name="enabled"
                  className="custom-control-input"
                  checked={enabled}
                  id={`enabled`}
                  onChange={(e) => {
                    handleInput(e);
                  }}
                />
                <label className="custom-control-label" htmlFor={`enabled`}>
                  <small>Enabled ?</small>
                </label>
              </div> */}
            </MDBCol>
          </MDBRow>
          <MDBRow center className="mx-0 my-2 p-0">
            <MDBCol sm="10" className="m-0 p-0 row">
              <MDBCol size="6" className="m-0 text-left">
                <label className="p-0 validating-label">
                  {validationMessage}&nbsp;
                </label>
              </MDBCol>
              <MDBCol size="6" className="m-0 text-right">
                <ActionButton
                  value={"Update"}
                  className="bg-Secondary"
                  type="submit"
                />
              </MDBCol>
            </MDBCol>
          </MDBRow>
        </form>
      </div>
      <LoaderModal modalShow={isLoading} />
      {
        <PasswordConfirmationModal
          modalShow={isConfirmingPassword}
          handleVerifyStatus={(verified) => {
            verified && handleUpdate();
            changeValue("isConfirmingPassword", false);
          }}
          hideModal={() => changeValue("isConfirmingPassword", false)}
        />
      }
    </Can>
  );
};

export default EditUser;
