import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { TabMenu } from "primereact/tabmenu";
import { Button } from "primereact/button";
import "../../styles/JobsDetailsModal.css"; // Import your custom styles
import { SelectButton } from "primereact/selectbutton";
import { Chart } from "primereact/chart";
import { FloatLabel } from "primereact/floatlabel";
import { Calendar } from "primereact/calendar";
import { addPayment, getPayementsByOrder } from "../../services/payments";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { getSettings } from "../../services/settings";
import { formatDateAndTime } from "../../Components/custom/Helpers";
import { getOrder, updateOrderTests } from "../../services/orders";
import { toast } from "react-toastify";
import { Checkbox } from "primereact/checkbox";
import LoaderModal from "../../Components/custom/LoaderModal";
import { getAllCourses } from "../../services/courses";
import { Dropdown } from "primereact/dropdown";

export const JobsDetailsModal = (props) => {
  const { modalShow, hideModal, order, paymentDetails } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedMenuType, setSelectedMenuType] = useState("Job Details");
  const [value, setValue] = useState("Job Details");
  const items = [
    { name: "Details", value: "Job Details" },
    { name: "Progress", value: "Progress" },
    { name: "Payments", value: "Payments" },
  ];
  //   const items = [
  //     { label: "Progress", icon: "pi pi-fw pi-check" },
  //     { label: "Order Details", icon: "pi pi-fw pi-list" },
  //     { label: "Payments", icon: "pi pi-fw pi-money-bill" },
  //   ];
  const handleMenutypeChange = (e) => {
    setSelectedMenuType(e.value);
    setValue(e.value);
  };
  return (
    <Dialog
      position="center"
      visible={modalShow}
      onHide={() => hideModal()}
      style={{ width: "calc(max-content + 100px)" }}
      className="jobs-details-modal"
    >
      {/* {order} */}
      {/* <TabMenu
        model={items}
        activeIndex={activeIndex}
        onTabChange={(e) => setActiveIndex(e.index)}
      /> */}
      <div className="d-flex flex-row justify-content-center align-items-center">
        <SelectButton
          value={value}
          onChange={handleMenutypeChange}
          optionLabel="name"
          options={items}
          className="md:w-25rem d-flex flex-row justify-content-center align-items-center"
        />
      </div>
      <div className="tab-content container">
        {selectedMenuType === "Job Details" && (
          <OrderDetailsContent Order={order} hideModal={hideModal} />
        )}
        {selectedMenuType === "Progress" && <ProgressContent job={order} />}
        {selectedMenuType === "Payments" && order && (
          <PaymentsContent order={order} paymentDetails={paymentDetails} />
        )}
        {/* {activeIndex === 0 && <ProgressContent />}
        {activeIndex === 1 && <OrderDetailsContent />}
        {activeIndex === 2 && <PaymentsContent />} */}
      </div>
      {/* <div className="modal-footer">
        <Button
          label="Close"
          onClick={() => hideModal()}
          className="p-button-secondary"
        />
      </div> */}
    </Dialog>
  );
};

const ProgressContent = ({ job }) => {
  // const courseHours = job?.order_detail?.course?.hours;

  const lessons = job?.lessons;
  const completedLessons = lessons?.filter(
    (lesson) => lesson.status === "completed"
  );
  const remainingLessons = lessons?.filter(
    (lesson) => lesson.status !== "completed"
  );

  const calculateCompletionPercentage = (lessons, courseHours) => {
    if (!lessons || !courseHours) return 0;

    const completedHours = lessons
      .filter((lesson) => lesson.status === "completed")
      .reduce((acc, lesson) => acc + lesson.duration / 60, 0); // Convert duration from minutes to hours

    const percentage = (completedHours / courseHours) * 100;
    return Math.min(100, Math.max(0, percentage)); // Ensure the value is between 0 and 100
  };

  const getProgressData = (job) => {
    console.log("job", job);

    const courseHours = job?.order_detail?.course?.hours;
    const lessons = job?.lessons;
    const completionPercentage = calculateCompletionPercentage(
      lessons,
      courseHours
    );
    return {
      labels: ["Completed", "Remaining"],
      datasets: [
        {
          data: [completionPercentage, 100 - completionPercentage],
          backgroundColor: ["#42A5F5", "#FF6384"],
        },
      ],
    };
  };

  return (
    <div className="progress-content">
      <div className="progress-summary">
        <div className="progress-item">
          <div className="progress-label">Total Lessons</div>
          <div className="progress-value">{lessons?.length}</div>
        </div>
        <div className="progress-item">
          <div className="progress-label">Completed</div>
          <div className="progress-value">{completedLessons?.length}</div>
        </div>
        <div className="progress-item">
          <div className="progress-label">Remaining</div>
          <div className="progress-value">{remainingLessons?.length}</div>
        </div>
      </div>
      <div className="progress-chart">
        <Chart
          type="doughnut"
          className="w-full md:w-9rem"
          data={getProgressData(job)}
          // options={options}
        />{" "}
        {/* <Chart type="doughnut" data={data} options={options} /> */}
        <div className="chart-text">{`${completedLessons?.length}/${lessons?.length}`}</div>
      </div>
    </div>
  );
};

const OrderDetailsContent = ({ Order, hideModal }) => {
  const [appliedDiscount, setAppliedDiscount] = useState(0);
  const [practicalTestPrice, setPracticalTestPrice] = useState(0);
  const [theoryTestPrice, setTheoryTestPrice] = useState(0);
  const [refreshData, setRefreshData] = useState(false);
  const [order, setOrder] = useState(Order);
  const [loading, setLoading] = useState(false);
  const [allCourses, setAllCourses] = useState([]);
  // Calculate the discount only when the order or coupons change
  useEffect(() => {
    const getDiscountAmount = (discountType) => {
      const orderTotal = order?.total;
      const coupon = order?.coupons[0];

      if (!coupon) return 0; // Return 0 if no coupon is found

      if (discountType === "percentage") {
        // Calculate percentage discount
        const orderTotalWithoutDiscount =
          orderTotal / (1 - coupon?.value / 100);
        return orderTotalWithoutDiscount - orderTotal; // Return percentage discount amount
      } else if (discountType === "fixed") {
        // Calculate fixed discount
        return Math.min(coupon?.value, orderTotal); // Return fixed discount, but not more than the order total
      } else {
        // If no valid discount type, return 0
        return 0;
      }
    };
    if (order?.coupons?.length >= 1) {
      const discountType = order?.coupons[0]?.type;
      const discount = getDiscountAmount(discountType);
      setAppliedDiscount(discount);
    }
  }, [order]);
  const [allSettings, setAllSettings] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const { data } = await getSettings();
        setAllSettings(data);
        setLoading(false);
        // Retrieve and set practical and theory test prices from the settings
        const foundTheoryTestPrice = data.find(
          (setting) => setting.name === "theory_test_price"
        )?.value;
        const foundPracticalTestPrice = data.find(
          (setting) => setting.name === "practical_test_price"
        )?.value;

        // Set the test prices
        setTheoryTestPrice(
          foundTheoryTestPrice ? parseFloat(foundTheoryTestPrice) : 0
        );
        setPracticalTestPrice(
          foundPracticalTestPrice ? parseFloat(foundPracticalTestPrice) : 0
        );
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    const getCourses = async () => {
      setLoading(true);
      await getAllCourses()
        .then((data) => {
          setAllCourses(data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    };
    getCourses();
    fetchData();
  }, []);
  // const fetchOrder = async () => {
  //   setLoading(true);
  //   // await fetch(`/api/getorder/${id}`)
  //   await getOrder(Order.id)
  //     .then((res) => {
  //       // setLoading(false);
  //       res.json().then((data) => {
  //         setLoading(false);
  //         setOrder(data);
  //       });
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //       console.log(err);
  //     });
  // };
  useEffect(() => {
    const fetchOrder = async () => {
      setLoading(true);
      try {
        const data = await getOrder(Order?.id); // getOrder already returns parsed JSON data
        setOrder(data); // set the order data
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    };

    fetchOrder();
  }, [Order?.id, refreshData]);
  // const theoryTestPrice = order?.order_detail?.added_theory_test
  //   ? parseFloat(
  //       allSettings.find((setting) => setting.name === "theory_test_price")
  //         ?.value
  //     )
  //   : 0;
  // const practicalTestPrice = order?.order_detail?.added_practical_test
  //   ? parseFloat(
  //       allSettings.find((setting) => setting.name === "practical_test_price")
  //         ?.value
  //     )
  //   : 0;
  const handleRemoveTheoryTest = async () => {
    const promoCodeDiscount = order?.coupons[0]?.value || 0;
    const discountType = order?.coupons[0]?.type || "";
    try {
      const isConfirmed = window.confirm("Are You Want To Remove Theory Test");
      if (isConfirmed) {
        console.log("Removing theory test");
        await updateOrderTests(
          order?.id,
          "theoryTest",
          theoryTestPrice,
          "remove",
          promoCodeDiscount,
          discountType
        )
          .then(() => {
            toast.warning("Theory Test Removed From Job");
            // hideModal();
            setRefreshData(!refreshData);
          })
          .catch((error) => {
            console.error("Error in Removing Theory Test", error);
          });
      }
    } catch (error) {
      console.error("Error removing theory test:", error);
    }
  };

  // Handler to remove practical test
  const handleRemovePracticalTest = async () => {
    const promoCodeDiscount = order?.coupons[0]?.value || 0;
    const discountType = order?.coupons[0]?.type || "";
    console.log("discountType", discountType);
    try {
      const isConfirmed = window.confirm(
        "Are You Want To Remove Practical Test"
      );
      if (isConfirmed) {
        console.log("Removing practical test");
        await updateOrderTests(
          order?.id,
          "practicalTest",
          practicalTestPrice,
          "remove",
          promoCodeDiscount,
          discountType
        )
          .then(() => {
            toast.warning("Practical Test Removed From Job");
            // hideModal();
            setRefreshData(!refreshData);
          })
          .catch((error) => {
            console.error("Error in Removing Practical Test", error);
          });
      }
    } catch (error) {
      console.error("Error removing practical test:", error);
    }
  };
  const handleAddTheoryTest = async () => {
    const promoCodeDiscount = order?.coupons[0]?.value || 0;
    const discountType = order?.coupons[0]?.type || "";
    try {
      const isConfirmed = window.confirm("Are You Want To Add Theory Test");
      if (isConfirmed) {
        console.log("Adding theory test");
        await updateOrderTests(
          order?.id,
          "theoryTest",
          theoryTestPrice,
          "add",
          promoCodeDiscount,
          discountType
        )
          .then(() => {
            toast.success("Theory Test Added To Job");
            // hideModal();
            setRefreshData(!refreshData);
          })
          .catch((error) => {
            console.error("Error in Adding Theory Test", error);
          });
        // toast.success("Test Added Successfully");
      }
    } catch (error) {
      console.error("Error Adding theory test:", error);
    }
  };
  const handleAddPracticalTest = async () => {
    const promoCodeDiscount = order?.coupons[0]?.value || 0;
    const discountType = order?.coupons[0]?.type || "";
    console.log("discountType", discountType);
    try {
      const isConfirmed = window.confirm("Are You Want To Add Practical Test");
      if (isConfirmed) {
        console.log("Adding practical test");
        await updateOrderTests(
          order?.id,
          "practicalTest",
          practicalTestPrice,
          "add",
          promoCodeDiscount,
          discountType
        )
          .then(() => {
            toast.success("Practical Test Added To Job");
            // hideModal();
            setRefreshData(!refreshData);
          })
          .catch((error) => {
            console.error("Error in Adding Practical Test", error);
          });
        // toast.success("Test Added Successfully");
      }
    } catch (error) {
      console.error("Error Adding practical test:", error);
    }
  };
  const handlePracticalTestCheckBox = () => {
    const checked = order?.order_detail?.added_practical_test;
    if (checked === true) {
      handleRemovePracticalTest();
    } else if (checked === false) {
      handleAddPracticalTest();
    } else {
      return;
    }
  };
  const handleTheoryTestCheckBox = () => {
    const checked = order?.order_detail?.added_theory_test;
    if (checked === true) {
      handleRemoveTheoryTest();
    } else if (checked === false) {
      handleAddTheoryTest();
    } else {
      return;
    }
  };

  return !loading ? (
    <div>
      {/* {JSON.stringify(order)} */}
      <div className="row">
        <div className="col-md-12">
          <span>
            <strong>Name: </strong>
            {order.customer.name}
          </span>

          <span className="float-end">
            <strong>Phone: </strong>
            {order.customer.phone}
          </span>
        </div>
        <div className="col-md-12">
          <span>
            <strong>Postcode: </strong>
            {order.customer.postcode}
          </span>
          <span className="float-end">
            <strong>Email: </strong>
            {order.customer.email}
          </span>
        </div>
      </div>
      <span>
        {" "}
        <Checkbox
          checked={order?.order_detail?.added_theory_test}
          className="mx-2"
          onChange={handleTheoryTestCheckBox}
        />
        Theory Test?
      </span>{" "}
      <span>
        <Checkbox
          checked={order?.order_detail?.added_practical_test}
          className="mx-2"
          onChange={handlePracticalTestCheckBox}
        />
        Practical Test?
      </span>
      <table>
        <thead>
          <tr>
            <th>Item</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {/* <Dropdown
                  id="couponType"
                  value={couponType}
                  options={allCourses}
                  onChange={(e) => setCouponType(e.value)}
                  placeholder="Select Coupon Type"
                  className="dialog-input"
                  required
                /> */}
              {order?.order_detail?.course?.hours} Hours Course
            </td>
            {/* <td>
              <select
                value={`${order?.order_detail?.course?.hours} Hours Course`}
                // onChange={handleChangeCourse}
              >
                {allSettings
                  .filter((setting) => setting.type === "course")
                  .map((course) => (
                    <option key={course.name} value={course.name}>
                      {course.hours} Hours Course
                    </option>
                  ))}
              </select>
            </td> */}
            <td>{order?.order_detail?.course?.price}</td>
          </tr>
          {order?.order_detail?.added_theory_test && (
            <tr>
              <td>Theory Test</td>
              <td>{theoryTestPrice}</td>
              {/* <button
                className="button-tertiary py-1 px-1 pi pi-times"
                onClick={handleRemoveTheoryTest}
              ></button> */}
            </tr>
          )}
          {order?.order_detail?.added_practical_test && (
            <tr>
              <td>Practical Test</td>
              <td>{practicalTestPrice}</td>
              {/* <button
                className="button-tertiary py-1 px-1 pi pi-times"
                onClick={handleRemovePracticalTest}
              ></button> */}
            </tr>
          )}
        </tbody>
      </table>
      <div className="mt-3 float-end" style={{ marginBottom: "50px" }}>
        {order?.coupons?.length >= 1 && (
          <div>
            <strong className="mx-5">Discount:</strong>
            {order?.coupons[0]?.type === "percentage" && (
              <span className="float-end">{appliedDiscount.toFixed(1)}</span>
            )}
            {order?.coupons[0]?.type === "fixed" && (
              <span className="float-end">{appliedDiscount.toFixed(1)}</span>
            )}
          </div>
        )}
        <div>
          <strong className="mx-5">Total:</strong>
          <span className="float-end">{order?.total}</span>
        </div>
        <div>
          <strong className="mx-5">Received:</strong>
          <span className="float-end">{order?.amount_paid || 0}</span>
        </div>
        <div>
          <strong className="mx-5">Remaining:</strong>
          <span className="float-end">{order?.balance || 0}</span>
        </div>
      </div>
    </div>
  ) : (
    <LoaderModal />
  );
  // return (
  //   <div>
  //     <table>
  //       <thead>
  //         <tr>
  //           <th>Item</th>
  //           <th>Price</th>
  //         </tr>
  //       </thead>
  //       <tbody>
  //         <tr>
  //           {/* <td>48 Hours Course</td> */}
  //           <td>{order?.order_detail?.course?.hours} Hours Course</td>
  //           <td>{order?.order_detail?.course?.price}</td>
  //         </tr>
  //         <tr>
  //           {order?.order_detail?.added_theory_test === true && (
  //             <>
  //               <td>Theory Test</td>
  //               <td>{theoryTestPrice}</td>
  //             </>
  //           )}
  //         </tr>
  //         <tr>
  //           {order?.order_detail?.added_practical_test === true && (
  //             <>
  //               <td>Practical Test</td>
  //               <td>{practicalTestPrice}</td>
  //             </>
  //           )}
  //         </tr>
  //       </tbody>
  //     </table>

  //     {/* <div className="mt-3 float-end" style={{ marginBottom: "50px" }}> */}
  //     <div className="mt-3 float-end" style={{ marginBottom: "50px" }}>
  //       {/* <p className="fs-4">Summary</p> */}
  //       {order?.coupons?.length >= 1 && (
  //         <div>
  //           <strong className="mx-5">Discount:</strong>

  //           {
  //             order?.coupons[0]?.type === "percentage" && (
  //               <span className="float-end">
  //                 {appliedDiscount.toFixed(1)}
  //                 {/* {getDiscountAmount(order?.coupons[0].type)} */}
  //                 {/* {(order?.total * order?.coupons[0].value) / 100} */}
  //                 {/* {(order?.total * order?.coupons[0].value) / 100} */}
  //               </span>
  //             )
  //             /* }<span className="float-end">{order?.total}</span> */
  //           }
  //         </div>
  //       )}
  //       <div>
  //         {/* <strong className="mx-5">Total:</strong> {order?.total} */}
  //         <strong className="mx-5">Total:</strong>

  //         <span className="float-end">{order?.total}</span>
  //       </div>
  //       <div>
  //         {/* <strong className="mx-5">Deposit:</strong> {order?.amount_paid} */}
  //         <strong className="mx-5">Recieved:</strong>{" "}
  //         {/* {typeof order?.amount_paid} */}
  //         {/* {typeof totalManualEnteredAmount} */}
  //         {/* {order?.amount_paid} */}
  //         {/* {totalManualEnteredAmount} */}
  //         {/* {order?.amount_paid} */}
  //         {/* {totalPaid} */}
  //         <span className=" float-end">{order?.amount_paid || 0}</span>
  //       </div>
  //       <div>
  //         <strong className="mx-5">Remaining:</strong>
  //         <span className=" float-end"> {order?.balance || 0}</span>
  //       </div>
  //     </div>
  //     {/* <div className="order-summary">
  //       <div>Total: 270</div>
  //       <div>Deposit: 70</div>
  //       <div>Remaining: 200</div>
  //     </div> */}
  //   </div>
  // );
};

const PaymentsContent = ({ order, paymentDetails }) => {
  const [paymentDialogVisible, setPaymentDialogVisible] = useState(false);
  // const [paymentDate, setPaymentDate] = useState();
  const [paymentMethod, setPaymentMethod] = useState();
  const [paymentAmount, setPaymentAmount] = useState();
  const [paymentNote, setPaymentNote] = useState();
  const [orderPayments, setOrderPayments] = useState([]);

  const [refreshData, setRefreshData] = useState(false);
  // const [updatedAmountPaid, setUpdatedAmountPaid] = useState();
  const orderId = order?.id;
  const customerId = order?.customer?.id;
  const totalManualEnteredAmount = paymentDetails?.reduce((sum, payment) => {
    return sum + parseFloat(payment.amount);
  }, 0);
  const totalPaid = totalManualEnteredAmount + parseFloat(order?.amount_paid);

  useEffect(() => {
    const getPaymentsViaOrder = async () => {
      try {
        await getPayementsByOrder(orderId)
          .then((data) => {
            console.log(data);
            setOrderPayments(data);
            console.log("orderPaymentDetails", setOrderPayments);
          })
          .catch((err) => console.log(err));
        // const { data } = await getPayementsByOrder(orderId);
        // console.log(data);
        // setOrderPaymentDetails(data);
        // console.log("orderPaymentDetails", orderPaymentDetails);
      } catch (error) {
        console.log(error);
      }
    };
    getPaymentsViaOrder();
  }, [orderId, refreshData]);

  const handlePaymentAdd = async (e) => {
    e.preventDefault();
    const updatedAmountPayed =
      parseFloat(order?.amount_paid) + parseFloat(paymentAmount);
    const paymentInfo = {
      amount: paymentAmount,
      payment_date: paymentDate,
      payment_method: paymentMethod,
      payment_note: paymentNote,
      order_id: orderId,
      customer_id: customerId,
    };
    await addPayment(paymentInfo)
      .then((res) => {
        setPaymentDialogVisible(false);
        setPaymentAmount("");
        setPaymentDate("");
        setPaymentMethod("");
        setPaymentNote("");
        setRefreshData(!refreshData);
        console.log("payment add response", res);
        // setUpdatedAmountPaid(
        //   parseFloat(order?.amount_paid) + parseFloat(res.amount)
        // );
        // update order amount paid
        // await updateOrder(order?.id, res.amount); // update order amount paid
        // await getOrder(order?.id); // refresh order details
        // await getOrders(); // refresh orders list
        // window.location.reload();
      })
      .catch((err) => console.log(err));
    // await updateOrder(order?.id, updatedAmountPayed);
    // await updateOrder(order?.id, totalPaid, remaining)
    //   .then((res) => {
    //     console.log(totalPaid);
    //     console.log("order Updated Successfully", res);

    //     window.location.reload();
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };
  const [paymentDate, setPaymentDate] = useState();
  return (
    <div>
      <Dialog
        header="Add New Payment"
        visible={paymentDialogVisible}
        style={{ width: "fit-content" }}
        // style={{ width: "50vw" }}
        onHide={() => {
          if (!paymentDialogVisible) return;
          setPaymentDialogVisible(false);
        }}
      >
        <form className="" onSubmit={handlePaymentAdd}>
          <div className="dialog-field-div mt-4">
            <FloatLabel>
              <Calendar
                value={paymentDate}
                onChange={(e) => setPaymentDate(e.value)}
                className="dialog-input small-calendar"
                showTime
                hourFormat="24"
                maxDate={new Date()}
                // showIcon
                // icon={() => <i className="pi pi-clock" />}
              />
              {/* <InputText
                type="text"
                className="dialog-input"
                value={paymentDate}
                onChange={(e) => {
                  setPaymentDate(e.target.value);
                }}
              /> */}
              <label>Payment Date</label>
            </FloatLabel>
          </div>
          {/* <br /> */}
          {/* <hr /> */}
          <div className="dialog-field-div">
            <FloatLabel>
              <InputText
                type="number"
                value={paymentAmount}
                className="dialog-input"
                max={order?.balance}
                onChange={(e) => {
                  setPaymentAmount(e.target.value);
                }}
              />
              <label>Payment Amount</label>
            </FloatLabel>
          </div>
          <div className="dialog-field-div">
            <FloatLabel>
              <InputText
                type="text"
                value={paymentMethod}
                className="dialog-input"
                onChange={(e) => {
                  setPaymentMethod(e.target.value);
                }}
              />
              <label>Payment Method</label>
            </FloatLabel>
          </div>{" "}
          <div className="dialog-field-div">
            <FloatLabel>
              <InputTextarea
                type="text"
                value={paymentNote}
                style={{ width: "" }}
                // className="dialog-input"
                onChange={(e) => {
                  setPaymentNote(e.target.value);
                }}
              />
              <label>Additional Note</label>
            </FloatLabel>
          </div>
          {/* <hr /> */}
          {/* <div className="dialog-field-div">
            <label htmlFor="isAllowed">Allowed:</label>
            <Checkbox
              type="checkbox"
              className="dialog-checkbox"
              id="isAllowed"
              // value={isAllowed}
              // checked={isAllowed === true}
              onChange={(e) => {
                // setIsAllowed(!isAllowed);
                // setIsAllowed(isAllowed);
              }}
            />
          </div> */}
          <button type="submit" className="btn dialog-button">
            Add Payment
          </button>
        </form>
        {/* <p className="m-0">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </p> */}
      </Dialog>
      <table>
        {/* {order.amount_paid > 0 && (
          <>
            {" "}
            <thead
              style={{ borderCollapse: "collapse", border: "1px solid black" }}
            >
              <tr>
                <th
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid black",
                  }}
                >
                  Date
                </th>
                <th
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid black",
                  }}
                >
                  Amount
                </th>
              </tr>
            </thead>
            <tbody> */}
        {/* {paymentDetails.map((payment, index) => (
            <tr key={index}>
              <td>{payment.date}</td>
              <td>{payment.amount}</td>
            </tr>
          ))} */}
        {/* {paymentDetails.map((payment, index) => ( */}
        {/* <tr>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid black",
                  }}
                >
                  {formatDateAndTime(order?.date)}
                </td>
                <td
                  style={{
                    borderCollapse: "collapse",
                    border: "1px solid black",
                  }}
                >
                  {order?.amount_paid || 0}
                </td>
              </tr> */}
        {/* ))} */}
        {/* </tbody>
          </>
        )} */}
        <thead>
          <tr>
            <th
            // style={{ borderCollapse: "collapse", border: "1px solid black" }}
            >
              Date
            </th>
            <th
            // style={{ borderCollapse: "collapse", border: "1px solid black" }}
            >
              Amount
            </th>
            <th
            // style={{ borderCollapse: "collapse", border: "1px solid black" }}
            >
              Method
            </th>
            <th
            // style={{ borderCollapse: "collapse", border: "1px solid black" }}
            >
              Note
            </th>
          </tr>
        </thead>

        <tbody>
          {" "}
          {orderPayments?.map((payment, index) => (
            <tr key={index}>
              <td
              // style={{
              //   borderCollapse: "collapse",
              //   border: "1px solid black",
              // }}
              >
                {formatDateAndTime(payment?.payment_date)}
              </td>
              <td
              // style={{
              //   borderCollapse: "collapse",
              //   border: "1px solid black",
              // }}
              >
                {payment?.amount}
              </td>
              <td
              // style={{
              //   borderCollapse: "collapse",
              //   border: "1px solid black",
              // }}
              >
                {payment?.payment_method}
              </td>
              <td
              // style={{
              //   borderCollapse: "collapse",
              //   border: "1px solid black",
              // }}
              >
                {payment?.payment_note}
                {/* {payment?.payment_note || "No Note provided"} */}
              </td>
            </tr>
          ))}
          {/* <tr>
            <td>20 Jul 2024</td>
            <td>70</td>
            <td>20 Jul 2024</td>
            <td>70</td>
          </tr> */}
          {paymentDetails?.map((payment, index) => (
            <tr key={index}>
              <td>{formatDateAndTime(paymentDetails.payment_date)}</td>
              <td>{paymentDetails.amount}</td>
              <td>{paymentDetails.payment_method}</td>
              <td>{paymentDetails.payment_note}</td>
            </tr>
          ))}
        </tbody>
        {/* <tbody>
          <tr>
            <td>20 Jul 2024</td>
            <td>70</td>
            <td>20 Jul 2024</td>
            <td>70</td>
          </tr>
        </tbody> */}
      </table>
      <Button
        label="Add New Payment"
        className="p-button-warning mt-3"
        onClick={() => {
          setPaymentDialogVisible(true);
        }}
      />
    </div>
  );
};

// import { Dialog } from "primereact/dialog";
// import { SelectButton } from "primereact/selectbutton";
// import { useState } from "react";

// export const JobsDetailsModal = (props) => {
//   const { modalShow } = props;
//   //   const [modalShow, setModalShow] = useState(false);
//   const [selectedCourseType, setSelectedCourseType] = useState("Progress");
//   const [value, setValue] = useState(null);
//   const items = [
//     { name: "Progress", value: "Progress" },
//     { name: "Job Details", value: "Job Details" },
//     { name: "Payments", value: "Payments" },
//   ];
//   const handleMenutypeChange = (e) => {
//     setSelectedCourseType(e.value);
//     setValue(e.value);
//   };
//   return (
//     <Dialog
//       position="center"
//       visible={modalShow}

//       //   onHide={() => {
//       //     // setModalShow(false);
//       //     setTimeout(() => {
//       //       props.hideModal();
//       //     }, 200);
//       //   }}
//     >
//       <SelectButton
//         value={value}
//         onChange={handleMenutypeChange}
//         optionLabel="name"
//         options={items}
//         className="md:w-25rem d-flex flex-row justify-content-center align-items-center"
//       />
//     </Dialog>
//   );
// };
