import React, { useState, useEffect, useRef, useContext } from "react";
import { Column } from "primereact/column";
import { getSettings, updateSetting } from "../services/settings.js";
import { Inplace, InplaceContent, InplaceDisplay } from "primereact/inplace";
import { MDBPopper } from "mdbreact";
import { Button } from "primereact/button";
import "../styles/AllJobs.css";
import { updateCourse } from "../services/courses.js";
import {
  deleteAllOrdersInTrash,
  deleteOrder,
  getAllOrders,
  getOrdersTrash,
  moveOrderToTrash,
  restoreAllOrdersFromTrash,
  restoreOrder,
  updateOrder,
  updateOrderTests,
} from "../services/orders.js";
import { CustomButton } from "../Components/UI/Customs.jsx";
import { getPayementsByOrder } from "../services/payments.js";
import { NewCustomListing } from "../Components/custom/NewCustomListing.jsx";
import SummaryCard from "../Components/UI/SummaryCards.jsx";
import {
  FaBoxOpen,
  FaChartLine,
  FaTh,
  FaTimesCircle,
  FaUsers,
} from "react-icons/fa";
import { JobsDetailsModal } from "./Modals/JobsDetailsModal.jsx";
import JobAssignModal from "./Modals/JobAssignModal.jsx";
import { toast } from "react-toastify";
import { AbilityContext } from "../configs/Ability-context.js";
import { Dropdown } from "primereact/dropdown";
import AddManualJobModal from "./Modals/AddManualJob.jsx";
import { CustomDatePicker } from "../Components/custom/CustomDatePicker.js";
const AllJobs = () => {
  const dt = useRef(null);
  const [
    {
      selectedOrderDate,
      //   allOrders,
      isReadingOrder,
      isLoading,
      refreshData,
      isUpdatingSetting,
      selectedSetting,
      activeEditor,
      editorText,
      selectedRecord,
      selectedRecords,
      trashView,
    },
    setState,
  ] = useState({
    selectedOrderDate: null,
    // allOrders: [],
    isLoading: false,
    isReadingOrder: false,
    refreshData: false,
    isUpdatingSetting: false,
    selectedSetting: null,
    activeEditor: { field: "", id: "" },
    editorText: "",
    trashView: false,
    selectedRecords: [],
  });
  const [allOrders, setallOrders] = useState([]);
  const [isConfirmingPassword, setIsConfirmingPassword] = useState(false);
  const [pendingUpdate, setPendingUpdate] = useState({ id: null, value: "" });
  const [orderPopulated, setOrderPopulated] = useState();
  const [orderPaymentDetails, setOrderPaymentDetails] = useState([]);
  const [allSettings, setAllSettings] = useState([]); // const ability = useContext(AbilityContext);
  const settingForm = useRef(null);
  const [showJobAssignModal, setShowJobAssignModal] = useState(false);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [currentFilter, setCurrentFilter] = useState("all");
  const [statusType, setStatusType] = useState("");
  const [manualJobVisible, setManualJobVisible] = useState(false);
  const [title, setTitle] = useState("Jobs");
  const statusTypes = [
    { label: "Pending", value: "pending" },
    { label: "New", value: "new" },
    { label: "Processing", value: "processing" },
    { label: "Completed", value: "completed" },
  ];
  let contextMenu = useRef(null);
  const ability = useContext(AbilityContext);
  const getOrders = async () => {
    await getAllOrders()
      .then((data) => {
        const sortedOrders = data.sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        );
        setallOrders(sortedOrders);
        setFilteredOrders(sortedOrders);
        setState((prevState) => ({
          ...prevState,
          trashView: false,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getOrders();
  }, [refreshData]);
  const changeValue = (name, value) => {
    // _isMounted
    //  &&
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getSettings();
        setAllSettings(data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);
  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { day: "numeric", month: "long", year: "numeric" };
    return new Intl.DateTimeFormat("en-GB", options).format(date);
  }
  const userRole = localStorage.getItem("userRole");
  const getPayementsViaOrder = async (orderId) => {
    try {
      await getPayementsByOrder(orderId)
        .then((data) => {
          console.log(data);
          setOrderPaymentDetails(data);
          console.log("orderPaymentDetails", orderPaymentDetails);
        })
        .catch((err) => console.log(err));
    } catch (error) {
      console.log(error);
    }
  };
  const handleUpdate = async (id, field, newValue) => {
    try {
      const response = await updateOrder(id, { [field]: newValue });
      console.log(id, { [field]: newValue });

      if (response.success) {
        allOrders.map((order) => {
          return order.id === id ? { ...order, [field]: newValue } : order;
        });
        console.log(response.message);
      } else {
        console.error(response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleRestore = (userId) => async (e) => {
    console.log(e);
    const isConfirmed = window.confirm(
      "Are You Sure You Want to Restore the Order"
    );
    if (isConfirmed) {
      changeValue("isLoading", true);
      try {
        const updatedOrders = allOrders.filter((order) => order.id !== userId);

        const { success, message } = await restoreOrder(userId);

        if (success) {
          toast.success("Order restored successfully");
          setallOrders(updatedOrders);
        } else {
          toast.error(message || "Something Went Wrong");
        }
      } catch (error) {
        toast.error("Something Went Wrong");
        console.log(error);
      } finally {
        changeValue("isLoading", false);
      }
    }
  };
  const handleMoveToTrash = (orderId) => async (e) => {
    console.log(orderId);
    const isConfirmed = window.confirm(
      trashView
        ? "Are You sure You want to delete this Job"
        : "Are You sure you want to move this Job to trash"
    );
    if (isConfirmed) {
      changeValue("isLoading", true);
      try {
        const { success, message } = trashView
          ? await deleteOrder(orderId)
          : await moveOrderToTrash(orderId);
        console.log(message);
        let updatedOrders = allOrders.filter((order) => order.id !== orderId);
        if (success) {
          setallOrders(updatedOrders);
          setFilteredOrders(updatedOrders);
          setState((prevState) => ({
            ...prevState,
            selectedRecords: [],
          }));
          toast.warning(
            trashView
              ? "Order Deleted Successfully"
              : "Order Moved to Trash Successfully"
          );
          // changeValue("refreshData", !refreshData);
        }
      } catch (error) {
        toast.current.show({
          severity: "error",
          summary: "Something went wrong",
        });
        console.log(error);
      }

      changeValue("isLoading", false);
    }
  };
  const handleGetTrash = () => {
    const fetchData = async () => {
      changeValue("isLoading", true);
      try {
        const { data } = await getOrdersTrash();
        console.log(data);
        setallOrders(data);
        setFilteredOrders(data);
        // _isMounted &&
        setState((prevState) => ({
          ...prevState,
          trashView: true,
        }));
      } catch (error) {
        console.log(error);
      }
      changeValue("isLoading", false);
    };
    fetchData();
  };
  const handleRestoreAll = async (e) => {
    // console.log("Restore All Clicked");
    const isConfirmed = window.confirm("Are you want to restore all Jobs?");
    if (isConfirmed) {
      changeValue("isLoading", true);
      try {
        const { success, message } = await restoreAllOrdersFromTrash();
        console.log(message);
        if (success) {
          changeValue("refreshData", !refreshData);
          toast.success("Jobs Restored Successfully");
        }
      } catch (error) {
        toast.current.show({
          severity: "error",
          summary: "Something went wrong",
        });
        console.log(error);
      }

      changeValue("isLoading", false);
    }
  };
  const handleEmptyTrash = async (e) => {
    const isConfirmed = window.confirm(
      "Are You Want to delete all Jobs Permanently?"
    );
    if (isConfirmed) {
      changeValue("isLoading", true);
      try {
        const { success, message } = await deleteAllOrdersInTrash();
        console.log(message);
        if (success) {
          changeValue("refreshData", !refreshData);
          toast.warning("Jobs Deleted Permanently From Trash");
        }
      } catch (error) {
        toast.current.show({
          severity: "error",
          summary: "Something went wrong",
        });
        console.log(error);
      }

      changeValue("isLoading", false);
    }
  };
  const DateBody = (rowData) => {
    const formattedDate = formatDate(rowData.date);
    return (
      <>
        <span>{formattedDate}</span>
      </>
    );
  };
  const ActionBody = (rowData) => {
    return (
      <div>
        <span
          className=" cursor-pointer p-2"
          onClick={() => {
            setOrderPopulated(
              allOrders.find((order) => {
                return order.id === rowData.id;
              })
            );
            setState((prevState) => ({
              ...prevState,
              //   selectedOrder: orderId,
              isReadingOrder: true,
            }));
          }}
          style={{
            backgroundColor: "#219EBC ",
            color: "white",
            // backgroundColor: "#D5D5D5 ",
            borderRadius: "5px 0px 0px 5px",
            width: "25px",
            margin: "1px",
          }}
        >
          <i className="pi pi-eye" style={{ color: "white" }}></i>
        </span>
        <span
          className=" cursor-pointer p-2"
          style={{
            backgroundColor: "#219EBC ",
            color: "white",
            borderRadius: "0px 5px 5px 0px",
          }}
          onClick={() => {
            setShowJobAssignModal(true);
            setOrderPopulated(
              allOrders.find((order) => {
                return order.id === rowData.id;
              })
            );
          }}
        >
          <i className="pi pi-plus" style={{ color: "white" }}></i>
        </span>
      </div>
    );
  };
  const handleStatusUpdate = async (rowData, field) => {
    await handleUpdate(rowData.id, field, statusType);
    setStatusType("");
    setState((prevState) => ({
      ...prevState,
      activeEditor: { field: "", id: "" },
      editorText: "",
      refreshData: !prevState.refreshData,
    }));
    // }
  };
  const setEditor = (field, id, value) => () => {
    setState((prevState) => ({
      ...prevState,
      activeEditor: { field, id },
      editorText: value,
    }));
  };

  const handleCancelEdit = () => {
    setStatusType("");
    setState((prevState) => ({
      ...prevState,
      activeEditor: { field: "", id: "" },
      editorText: "",
    }));
  };

  const statusBody = (rowData) => {
    const field = "status";
    const StatusfirstLetterUpperCase = rowData[field]
      ?.slice(0, 1)
      .toUpperCase();

    return (
      <Inplace
        closable={false}
        // closable
        active={activeEditor.field === field && activeEditor.id === rowData.id}
        onOpen={setEditor(field, rowData.id, rowData[field])}
      >
        <InplaceDisplay>
          <MDBPopper domElement tag="span" placement="right">
            <span className={`status-${rowData?.status?.toLowerCase()}`}>
              {/* {rowData[field]} */}
              {StatusfirstLetterUpperCase + rowData[field]?.slice(1)}
            </span>
            <span>{" Click to edit"}</span>
          </MDBPopper>
        </InplaceDisplay>
        <InplaceContent>{getStatusEditor(rowData, field)}</InplaceContent>
      </Inplace>
    );
  };
  const getStatusEditor = (rowData, field) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Dropdown
          id="couponType"
          value={statusType}
          options={statusTypes}
          onChange={(e) => setStatusType(e.value)}
          placeholder="Select Status"
          required
        />
        <Button
          icon="pi pi-check"
          className="p-button-rounded p-button-success p-mr-2"
          onClick={() => handleStatusUpdate(rowData, field)}
        />
        <Button
          icon="pi pi-times"
          className="p-button-rounded p-button-danger"
          onClick={handleCancelEdit}
        />
      </div>
    );
  };
  const handleManualJobCreate = () => {
    console.log("create");
    setManualJobVisible(true);
  };
  const handleOrderUpdate = async (orderId, testType, testPrice, action) => {
    try {
      // Update order tests
      await updateOrderTests(orderId, testType, testPrice, action);
      const updatedOrder = allOrders.find(
        (order) => order.id === JSON.parse(orderId)
      ); // Replace with your function to fetch the order by id
      setOrderPopulated(updatedOrder); // Update the order state with fresh data
      toast.success(
        `Test ${action === "add" ? "added" : "removed"} successfully!`
      );
    } catch (error) {
      console.error("Error updating test:", error);
    }
  };
  const newJobs = allOrders?.filter((order) => {
    return order.status === "new";
  });
  const inProcess = allOrders?.filter((order) => {
    return order.status === "processing";
  });
  const completed = allOrders?.filter((order) => {
    return order.status === "completed";
  });
  const cancelled = allOrders?.filter((order) => {
    return order.status === "pending";
  });
  let menuModel = trashView
    ? [
        {
          label: "Restore this record",
          icon: "pi pi-undo color-primary-light lead",
          command: (e) => {
            handleRestore(selectedRecord.id)(e);
          },
        },
        {
          label: "Delete this record",
          icon: "pi pi-trash color-primary-light lead",
          command: (e) => {
            handleMoveToTrash(selectedRecord.id)(e);
          },
        },
      ]
    : [
        {
          label: "Move to trash",
          icon: "pi pi-trash color-primary-light lead",
          command: (e) => {
            console.log(e);
            handleMoveToTrash(selectedRecord.id)(e);
          },
        },
      ];
  const onDateChange = (value, field, stateVariable) => {
    dt.current.filter(value, field, "custom");
    changeValue(stateVariable, value);
  };
  const dateFilter = (value, field, stateVariable, placeholder) => {
    return (
      <CustomDatePicker
        selectedValue={value}
        handleChange={(value) => {
          onDateChange(value, field, stateVariable);
        }}
        placeholder={placeholder}
        isClearable
      />
    );
  };
  const statusOptions = [
    { label: "Pending", value: "Pending" },
    { label: "Processing", value: "Processing" },
    { label: "New", value: "New" },
    { label: "Completed", value: "Completed" },
  ];

  const statusFilter = (options) => (
    <Dropdown
      value={options.value}
      options={statusOptions}
      onChange={(e) => options.filterApplyCallback(e.value)}
      placeholder="Select a Status"
      className="p-column-filter"
    />
  );

  const columns = [
    {
      field: "customer.name",
      header: "Name",
      filter: true,
      //   body: (rowData) => inlineEditor("status", rowData),
      selectedByDefault: true,
      // filterPlaceholder: "Search by value",
      filterMatchMode: "contains",
      headerStyle: { width: "290px" },
    },
    {
      field: "customer.postcode",
      header: "PostCode",
      filter: true,
      //   body: (rowData) => inlineEditor("status", rowData),
      selectedByDefault: true,
      filterPlaceholder: "Search by Postcode",
      filterMatchMode: "contains",
      // headerStyle: { width: "290px" },
    },
    {
      field: "date",
      header: "Date",
      body: (rowData) => DateBody(rowData),
      filter: true,
      selectedByDefault: true,
      sortable: true,
      // filterPlaceholder: "Search by value",
      filterElement: dateFilter(
        selectedOrderDate,
        "date",
        "selectedOrderDate",
        "Select order date"
      ),
      filterFunction: "filterDate",
      headerStyle: { width: "290px" },
    },
    {
      //   field: "status",
      header: "Order Actions",
      //   filter: true,
      body: (rowData) => ActionBody(rowData),
      //   body: (rowData) => inlineEditor("status", rowData),
      selectedByDefault: true,
      filterPlaceholder: "Search by value",
      filterMatchMode: "contains",
      headerStyle: { width: "290px" },
    },
    {
      field: "status",
      header: "Status",
      body: (rowData) => statusBody(rowData),
      filter: true,
      selectedByDefault: true,
      filterPlaceholder: "Search by value",
      filterElement: statusFilter, // Custom filter element for dropdown
      filterMatchMode: "equals", // Use 'equals' since we are using dropdown
    },
  ];

  return userRole === `"admin"` ? (
    <div className="mt-3 pb-5">
      <AddManualJobModal
        visible={manualJobVisible}
        setVisible={setManualJobVisible}
        refreshData={refreshData}
        changeValue={changeValue}
      />
      <JobsDetailsModal
        modalShow={isReadingOrder}
        order={orderPopulated}
        handleOrderUpdate={handleOrderUpdate}
        hideModal={() => {
          // _isMounted &&
          setState((prevState) => ({
            ...prevState,
            isReadingOrder: false,
            selectedOrder: null,
            // refreshData: !refreshData,
          }));
        }}
      />
      <JobAssignModal
        handleUpdate={handleUpdate}
        modalShow={showJobAssignModal}
        hideModal={() => {
          setShowJobAssignModal(false);
        }}
        order={orderPopulated}
      />

      <form
        className="p-m-0 p-pt-4"
        noValidate
        onSubmit={(e) => e.preventDefault()}
      >
        {" "}
        <div className="summary-card-container container">
          <SummaryCard
            title="All Jobs"
            value={allOrders?.length}
            icon={<FaTh />} // or <FaListAlt /> or <FaTh />
            // icon={<FaListAlt />} // or <FaBriefcase /> or <FaTh />
            color="#E0F7FA" // or #F5F5F5, #E0F4F4
            onClick={() => {
              setFilteredOrders(allOrders);
              setCurrentFilter("");
              setTitle("Jobs");
            }}
          />
          <SummaryCard
            title="New Jobs"
            value={newJobs?.length}
            icon={<FaUsers />}
            color="#D9E5FF"
            onClick={() => {
              setFilteredOrders(newJobs);
              setCurrentFilter("new");
              setTitle("New Jobs");
            }}
          />

          <SummaryCard
            title="In Process"
            value={inProcess?.length}
            icon={<FaBoxOpen />}
            color="#FFF3D4"
            onClick={() => {
              setFilteredOrders(inProcess);
              setCurrentFilter("processing");
              setTitle("In Process Jobs");
            }}
          />
          <SummaryCard
            title="Completed"
            value={completed?.length}
            icon={<FaChartLine />}
            color="#DFF5E4"
            onClick={() => {
              setFilteredOrders(completed);
              setCurrentFilter("completed");
              setTitle("Completed Jobs");
            }}
          />
          <SummaryCard
            title="Pending"
            value={cancelled?.length}
            icon={<FaTimesCircle />}
            color="#FFE5E5"
            onClick={() => {
              setFilteredOrders(cancelled);
              setCurrentFilter("pending");
              setTitle("Pending Jobs");
            }}
          />
        </div>
        <NewCustomListing
          showPaginator={true}
          allRecords={filteredOrders}
          // allRecords={allOrders}
          heading={trashView ? `${title} (trash)` : title}
          firstColumn={
            <Column
              field="id"
              header="ID"
              style={{ borderBottom: "1px solid grey" }}
              sortable={true}
              //   body={(rowData) => idTemplate(rowData, handleView)}
              //   filter={true}
              filterPlaceholder="Search by name"
              filterMatchMode="contains"
              headerStyle={{ width: "170px" }}
            />
          }
          columns={columns}
          permissionSubject={"orders"}
          isLoading={isLoading}
          handleRefresh={
            trashView ? false : () => changeValue("refreshData", !refreshData)
          }
          handleDelete={handleMoveToTrash}
          handleTrash={
            trashView
              ? () => changeValue("refreshData", !refreshData)
              : handleGetTrash
          }
          extraButton={
            trashView
              ? (customButton, rowData) => {
                  return ability.can("restore", "products")
                    ? customButton(
                        "pi pi-refresh color-primary-light",
                        // "fas fa-undo-alt fa-sm color-primary",
                        (e) => {
                          handleRestore(rowData.id)(e);
                        },
                        false,
                        "restore this record"
                      )
                    : null;
                }
              : ""
          }
          handleCreate={handleManualJobCreate}
          handleEmptyTrash={trashView ? handleEmptyTrash : null}
          handleRestoreAll={trashView ? handleRestoreAll : null}
          contextMenuRef={contextMenu}
          contextMenuModel={menuModel}
          trashLabel={trashView ? "Jobs" : `Trash`}
          trashIcon={trashView ? "pi pi-refresh" : "pi pi-trash"}
          selectedRecords={selectedRecords}
          selectionDataKey={"id"}
          contextMenuSelection={selectedRecord}
          tableSmall
          deleteDataKey={"id"}
          editDataKey={"id"}
          tableRef={dt}
          timeStamps={true}
        />
      </form>
    </div>
  ) : (
    <>
      <div>you are not allowed to see this page</div>
    </>
  );
};

export default AllJobs;
