// Sidebar.js
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  FaBars,
  FaChevronDown,
  FaCar,
  FaBriefcase,
  FaMoneyCheckAlt,
  FaBook,
  FaPoundSign,
  FaCarSide,
  FaUserFriends,
} from "react-icons/fa";
import {
  RiCoupon3Fill,
  RiLogoutBoxRLine,
  RiUserSettingsFill,
} from "react-icons/ri";
import { RiSettings3Line } from "react-icons/ri";
import "../styles/Sidebar.css";
import { SiStudyverse } from "react-icons/si";
import { LiaFirstOrder } from "react-icons/lia";
import { PiMapPinAreaFill } from "react-icons/pi";
import { FaChevronRight } from "react-icons/fa";
const Sidebar = ({
  collapsed,
  onToggleSidebar,
  handleLogOut,
  userRole,
  userId,
}) => {
  const [isUsersExpanded, setIsUsersExpanded] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const [isSmallScreen, setIsSmallScreen] = useState(false);
  // const navigate = useNavigate();
  // const { logout } = useAuth();
  const sidebarRef = useRef(null);
  const toggleButtonRef = useRef(null);

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsSmallScreen(true);
    } else {
      setIsSidebarOpen(true);
      setIsSmallScreen(false);
    }
  };
  useEffect(() => {
    // Add event listener to handle window resize
    window.addEventListener("resize", handleResize);
    console.log("isSmallScreen", isSmallScreen);
    // Call handleResize once to set initial state
    handleResize();

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isSmallScreen]);

  // Close sidebar when tapping outside on small screens

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        isSmallScreen &&
        isSidebarOpen &&
        !e.target.closest(".sidebar") &&
        !e.target.closest(".toggle-btn")
      ) {
        // console.log("triggers");
        setIsSidebarOpen(false);
      }
    };
    if (isSmallScreen) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isSmallScreen, isSidebarOpen]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    console.log("toggle sidebar");
    console.log(isSidebarOpen);
  };
  const renderAdminSideBar = () => (
    <>
      {" "}
      {/* <li className="sidebar-item">
        <Link to="/profile" className="sidebar-link">
          <FaUserCircle className="sidebar-icon" />
          <span className="sidebar-label">Profile</span>
        </Link>
      </li> */}
      {/* <li className="sidebar-item">
        <Link to="/dashboard" className="sidebar-link">
          <RiDashboardLine className="sidebar-icon" />
          <span className="sidebar-label">Dashboard</span>
        </Link>
      </li> */}
      {/* <li className="sidebar-item">
        <Link to="/users/all" className="sidebar-link">
          <FaUsers className="sidebar-icon" />
          <span className="sidebar-label">Users</span>
        </Link>
      </li> */}
      {/* <li className="sidebar-item">
        <div>
          <Link to="/users/all" className="sidebar-link">
            <RiUserSettingsFill className="sidebar-icon" />
            <span className="sidebar-label">Users</span>
          </Link>
          <RiArrowDownDoubleFill onClick={toggleUsersMenu} />
        </div>
      </li> */}
      <li className="sidebar-item">
        <div className="sidebar-link-container" onClick={toggleUsersMenu}>
          <Link to="/admin/users/all" className="sidebar-link">
            <RiUserSettingsFill className="sidebar-icon" />
            <span className="sidebar-label">Users</span>
          </Link>
          {isUsersExpanded ? (
            <FaChevronDown
              className="sidebar-toggle-icon "
              // onClick={toggleUsersMenu}
            />
          ) : (
            <FaChevronRight
              className="sidebar-toggle-icon "
              // onClick={toggleUsersMenu}
            />
          )}
        </div>
        {isUsersExpanded && (
          <ul className="sidebar-submenu">
            <div className="sidebar-subitem mx-3  mt-2 mb-2">
              <Link
                to="/admin/user/roles"
                className="sidebar-sublink text-white text-decoration-none "
              >
                {/* <i className="pi pi-circle"></i> */}
                <span className="sidebar-sublabel">Roles</span>
              </Link>
            </div>
            <div className="sidebar-subitem mx-3 mt-2 mb-2">
              <Link
                to="/admin/user/permissions"
                className="sidebar-sublink text-white text-decoration-none "
              >
                {/* <i className="pi pi-circle"></i> */}
                <span className="sidebar-sublabel">Permissions</span>
              </Link>
            </div>
          </ul>
        )}
      </li>
      {/* <li className="sidebar-item">
        <Link to="users/all" className="sidebar-link"> */}
      {/* <span className="sidebar-label">Users</span> */}
      {/* <li className="sidebar-item"> */}
      {/* <RiUserSettingsFill className="sidebar-icon" /> */}
      {/* <TieredMenu
          model={items}
          breakpoint="767px"
          className="sidebar-item float-start"
        /> */}
      {/* </li> */}
      {/* </Link>
      </li> */}
      {/* <li className="sidebar-item">
        <Link to="users/all" className="sidebar-link">
          <RiUserSettingsFill className="sidebar-icon" />
          <span className="sidebar-label">Users</span>
          <Dropdown />
        </Link>
      </li> */}
      <li className="sidebar-item">
        <Link to="/admin/courses/all" className="sidebar-link">
          <SiStudyverse className="sidebar-icon" />
          <span className="sidebar-label">Courses</span>
        </Link>
      </li>
      {/* <li className="sidebar-item">
        <Link to="/orders/all" className="sidebar-link">
          <LiaFirstOrder className="sidebar-icon" />
          <span className="sidebar-label">Orders</span>
        </Link>
      </li> */}
      <li className="sidebar-item">
        <Link to="/admin/jobs/all" className="sidebar-link">
          <LiaFirstOrder className="sidebar-icon" />
          <span className="sidebar-label">Jobs</span>
        </Link>
      </li>
      <li className="sidebar-item">
        <Link to="/admin/lessons/all" className="sidebar-link">
          <FaCar className="sidebar-icon" />
          {/* <FaChalkboardTeacher className="sidebar-icon" /> */}
          <span className="sidebar-label">Lessons</span>
        </Link>
      </li>
      <li className="sidebar-item">
        <Link to="/admin/areas/all" className="sidebar-link">
          <PiMapPinAreaFill className="sidebar-icon" />
          <span className="sidebar-label">Areas</span>
        </Link>
      </li>
      {/* <li className="sidebar-item">
        <Link to="/instructors" className="sidebar-link">
          <AiOutlineShop className="sidebar-icon" />
          <span className="sidebar-label">Instructors</span>
        </Link>
      </li> */}
      <hr />
      <li className="sidebar-item">
        <Link to="/admin/coupons/all" className="sidebar-link">
          <RiCoupon3Fill className="sidebar-icon" />
          <span className="sidebar-label">Coupons</span>
        </Link>
      </li>{" "}
      <li className="sidebar-item">
        <Link to="/admin/settings/all" className="sidebar-link">
          <RiSettings3Line className="sidebar-icon" />
          <span className="sidebar-label">Settings</span>
        </Link>
      </li>{" "}
      {/* <li className="sidebar-item" onClick={handleLogOut}>
        <Link className="sidebar-link">
          <RiLogoutBoxRLine className="sidebar-icon" />
          <span className="sidebar-label">Logout</span>
        </Link>
      </li> */}
      <hr />
    </>
  );
  const renderInstructorSidebar = () => (
    <>
      <li className="sidebar-item">
        <Link to={`/instructor/jobs`} className="sidebar-link">
          <FaBriefcase className="sidebar-icon" />
          <span className="sidebar-label">My Jobs</span>
        </Link>
      </li>
      <li className="sidebar-item">
        <Link to={`/instructor/lessons`} className="sidebar-link">
          <FaCar className="sidebar-icon" />
          <span className="sidebar-label">My Lessons</span>
        </Link>
      </li>
      <li className="sidebar-item">
        <Link to={`/instructor/learners`} className="sidebar-link">
          <FaUserFriends className="sidebar-icon" />
          <span className="sidebar-label">Learners</span>
        </Link>
      </li>
      <li className="sidebar-item">
        <Link to={`/instructor/payments`} className="sidebar-link">
          <FaMoneyCheckAlt className="sidebar-icon" />
          <span className="sidebar-label">Payments</span>
        </Link>
      </li>
      <hr />
    </>
  );
  const renderStudentSidebar = () => {
    return (
      <>
        <li className="sidebar-item">
          <Link to={`/student/courses`} className="sidebar-link">
            <FaBook className="sidebar-icon" />
            <span className="sidebar-label">My Courses</span>
          </Link>
        </li>
        <li className="sidebar-item">
          <Link to={`/student/lessons`} className="sidebar-link">
            <FaCarSide className="sidebar-icon" />
            <span className="sidebar-label">My Lessons</span>
          </Link>
        </li>
        <li className="sidebar-item">
          <Link to={`/student/payments`} className="sidebar-link">
            <FaPoundSign className="sidebar-icon" />
            <span className="sidebar-label">My Payments</span>
          </Link>
        </li>
      </>
    );
  };
  // const userRole = localStorage.getItem("userRole");
  // const userId = localStorage.getItem("userId");
  const toggleUsersMenu = () => {
    setIsUsersExpanded(!isUsersExpanded);
  };
  // const handleLogOut = () => {
  //   // fetch("/api/auth/logout")
  //   //   .then((res) => res.json())
  //   //   .then((json) => {
  //   // console.log(json)
  //   logout();
  //   window.location.replace("/");
  //   // navigate("/");
  //   // })
  //   // .catch((err) => console.log(err));
  // };
  const items = [
    {
      label: "Users",
      icon: "pi pi-users",
      // url: "/all/users",
      items: [
        {
          label: "Roles",
          icon: "pi pi-circle",
          url: "/user/roles",
        },
        {
          label: "Permissions",
          icon: "pi pi-circle",
          url: "/user/permissions",
        },
      ],
    },
  ];

  return (
    // userRole === `"admin"` && (
    <>
      {/* Toggle button for mobile view */}
      {!isSidebarOpen && (
        <button
          className="toggle-btn"
          onClick={toggleSidebar}
          ref={toggleButtonRef}
        >
          <FaBars />
        </button>
      )}

      {/* Sidebar */}
      <div
        className={`sidebar ${isSidebarOpen ? "open" : ""} ${
          collapsed ? "collapsed" : ""
        }`}
        ref={sidebarRef} // Attach ref to sidebar
      >
        <div className="sidebar-header">
          <Link to="/">
            <img
              src={require("../Assets/logo.webp")}
              alt="Logo"
              className="logo"
            />
          </Link>
          {isSidebarOpen && (
            <button className="collapse-btn " onClick={toggleSidebar}>
              <FaBars />
            </button>
          )}
          {/* <button className="collapse-btn" onClick={onToggleSidebar}>
            <FaBars />
          </button> */}
        </div>
        <div className="sidebar-content">
          <ul className="sidebar-menu">
            {userRole === `"admin"` && renderAdminSideBar()}
            {userRole === `"instructor"` && renderInstructorSidebar()}
            {userRole === `"customer"` && renderStudentSidebar()}
            <li className="sidebar-item" onClick={handleLogOut}>
              <Link className="sidebar-link">
                <RiLogoutBoxRLine className="sidebar-icon" />
                <span className="sidebar-label">Logout</span>
              </Link>
            </li>
            <hr />
          </ul>
        </div>
        {!collapsed && (
          <div
            className="mt-5 sidebar-item"
            style={{ position: "fixed", bottom: "5px", left: "15px" }}
          >
            Version 0.9
          </div>
        )}
      </div>
    </>
    // <div
    //   className={`sidebar d-none d-sm-block
    // d-sm-none d-md-block ${collapsed ? "collapsed" : ""}`}
    // >
    //   <div className="sidebar-header">
    //     <img src={require("../Assets/logo.png")} alt="Logo" className="logo" />
    //     <button className="collapse-btn" onClick={onToggleSidebar}>
    //       <FaBars />
    //     </button>
    //   </div>
    //   <div className="sidebar-content">
    //     <ul className="sidebar-menu">
    //       {userRole === `"admin"` && renderAdminSideBar()}
    //       {userRole === `"instructor"` && renderInstructorSidebar()}
    //       {userRole === `"customer"` && renderStudentSidebar()}
    //       <li className="sidebar-item" onClick={handleLogOut}>
    //         <Link className="sidebar-link">
    //           <RiLogoutBoxRLine className="sidebar-icon" />
    //           <span className="sidebar-label">Logout</span>
    //         </Link>
    //       </li>
    //       <hr />
    //     </ul>
    //   </div>
    //   <div
    //     className="sidebar-item"
    //     //  className="sidebar-footer"
    //   >
    //     {/* <Link to="/logout" className="sidebar-link">
    //       <FaSignOutAlt className="sidebar-icon" />
    //       <span className="sidebar-label">Logout</span>
    //     </Link> */}
    //   </div>
    //   {!collapsed && (
    //     <div
    //       className="mt-5 sidebar-item"
    //       style={{ position: "fixed", bottom: "5px", left: "15px" }}
    //     >
    //       Version 0.7
    //     </div>
    //   )}
    //   {/* <div
    //     className="mt-5 sidebar-item"
    //     style={{ position: "fixed", bottom: "5px", left: "15px" }}
    //   >
    //     Version 0.3
    //   </div> */}
    // </div>
  );
};

export default Sidebar;
