import React from "react";
import "../styles/BenifitsSection.css";
import { Link } from "react-router-dom";

const BenifitsSection = () => {
  return (
    <div className="benefits-section text-center row justify-content-center align-items-center mt-5">
      <span className="text-center mb-5 display-6" style={{ fontWeight: 500 }}>
        Why Pass-Swiftly?
      </span>
      <div className="row justify-content-center align-items-center">
        <div className="benefits-item col-md-3 flexible-hours  ">
          <div className="image-container">
            <img
              className="benefit-image"
              sizes=""
              src={require("../Assets/benifitsImages/clock icon 1.webp")}
              alt=""
            />
          </div>
          <div className="text-card-heading">
            Customize and Flexible Lessons
          </div>
          <div className="text-card">
            <p>
              Our adaptive schedule is crafted to align with your unique
              requirements. Intensive lessons can vary from daily
              <strong> two-hour session</strong> to consecutive days of
              back-to-back lessons. These sessions are customized to cater to
              your individual needs, considering your previous experience.
            </p>
          </div>
        </div>
        <div className="benefits-item col-md-3">
          <div className="image-container" style={{ marginBottom: "3px" }}>
            <img
              className="benefit-image"
              src={require("../Assets/benifitsImages/driving instructors 1.webp")}
              alt=""
            />
          </div>
          <div className="text-card-heading">
            Experienced and skilled Instructors
          </div>
          <div className="text-card">
            <p>
              If you’re eager to swiftly pass your driving test and obtain your
              license,Pass Swiftly is your solution.Our highly
              <strong> skilled instructors</strong> will thoroughly prepare you
              to excel in your test within just <strong> 2-3 weeks </strong>.,
              helping your goal.
            </p>
          </div>
        </div>
      </div>{" "}
      <div className="row justify-content-center align-items-center">
        <div className="benefits-item col-md-3">
          <div className="image-container">
            <img
              className="benefit-image"
              sizes=""
              src={require("../Assets/benifitsImages/pass success 1.webp")}
              alt=""
            />
          </div>
          <div className="text-card-heading">Our Success Stories </div>
          <div className="text-card">
            <p>
              Since 2015, out intensive driving courses have successfully
              assited 100s of students in passing their driving tests.
            </p>
          </div>
        </div>
        <div className="benefits-item col-md-3">
          <div className="image-container">
            <img
              className="benefit-image"
              src={require("../Assets/benifitsImages/affordable pricing 1.webp")}
              alt=""
            />
          </div>
          <div className="text-card-heading">Budget-Friendly Pricing</div>
          <div className="text-card">
            <p>
              We offer our courses at highly competitive prices. Explore our
              pricing options{" "}
              <Link to="/courses">
                <u style={{ cursor: "pointer" }}>here.</u>
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BenifitsSection;
