import React from "react";
// import "mdbreact/dist/css/mdb.css";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "primereact/resources/themes/saga-orange/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./styles/index.css";
import "./styles/App.css";
import "./styles/App.scss";
import "./styles/index.scss";
import PrimeReact from "primereact/api";
import { UserContext } from "./contexts/UserContext";
import { AppContext } from "./contexts/AppContext";
import { AuthProvider } from "./contexts/AuthContext";
import { GlobalProvider } from "./contexts/GlobalContext";
PrimeReact.ripple = true;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <AuthProvider>
      <GlobalProvider>
        <AppContext.Provider>
          <React.StrictMode>
            <App />
          </React.StrictMode>
        </AppContext.Provider>
      </GlobalProvider>
    </AuthProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
