import React from "react";
import "../styles/AboutUs.css"; // This is where you can add custom styles

const AboutUs = () => {
  return (
    <div className="about-us-container">
      <div className="about-us-content">
        <h1>About Us</h1>
        <p>
          Pass Swiftly Ltd is dedicated to providing top-notch driving
          instruction to learners in Glasgow and surrounding areas, with plans
          to expand across the UK. Our mission is to empower students of all
          levels with the skills and confidence needed to become safe and
          competent drivers. Whether you're a beginner, an intermediate learner,
          or someone looking to refresh your driving skills, we have the right
          course for you. We pride ourselves on our professional instructors,
          modern teaching methods, and a supportive learning environment. At
          Pass Swiftly Ltd, we're committed to helping you pass swiftly and
          safely.
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
