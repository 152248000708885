import { Card } from "primereact/card";
import { SelectButton } from "primereact/selectbutton";
import React, { useEffect, useState } from "react";
import { getAllCourses } from "../services/courses";
import "../styles/Courses.css";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

const Courses = () => {
  const [courses, setCourses] = useState([]);
  const [value, setValue] = useState("Beginner");
  const [selectedCourseType, setSelectedCourseType] = useState("Beginner");
  const items = [
    { name: "Beginner", value: "Beginner" },
    { name: "Intermediate", value: "Intermediate", className: "px-4" },
    { name: "Refresher", value: "Refresher" },
  ];
  const location = useLocation();
  const { category } = useParams();
  let navigate = useNavigate();
  const handleCoursetypeChange = (e) => {
    const newCourseType = e.value;
    setSelectedCourseType(e.value);
    setValue(e.value);
    navigate(`/courses/${newCourseType.toLowerCase()}`);
  };

  const getCourses = async () => {
    await getAllCourses()
      .then((data) => {
        setCourses(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    // const queryParams = new URLSearchParams(location.search);
    // const courseType = queryParams.get("courseType");
    // setSelectedCourseType(courseType ? courseType : "Beginner");

    // setValue(courseType ? courseType : "Beginner");
    const initialCourseType = category
      ? category.charAt(0).toUpperCase() + category.slice(1)
      : "Beginner";
    setSelectedCourseType(initialCourseType);
    getCourses();
  }, []);

  const renderCourses = () => {
    return courses
      .filter((course) => course.category === selectedCourseType)
      .map((course, index) => (
        <div key={index} className="card-container">
          <Card
            title={() => (
              <>
                <i className="pi pi-clock" style={{ fontSize: "20px" }} />{" "}
                {course.hours} Hours Course <br />
                <i
                  className="pi pi-calendar"
                  style={{ fontSize: "20px" }}
                />{" "}
                {course.duration}
              </>
            )}
            // subTitle={() => (
            //   <>
            //     <i className="pi pi-pound" style={{ color: "white" }} />
            //     <strong style={{ fontSize: "20px", color: "white" }}>
            //       {course.price}
            //     </strong>
            //   </>
            // )}
            className="md:w-25rem course-card"
            footer={() => (
              <div className="d-flex justify-content-center">
                {/* <Link to="/booking"> */}
                <Link
                  to={`/booking?courseId=${course.id}&courseType=${selectedCourseType}`}
                >
                  <button
                    className={`btn course-button ${selectedCourseType.toLowerCase()} mx-1`}
                  >
                    Book Now
                  </button>
                </Link>
                <Link to={`/course/${course?.slug}`}>
                  <button
                    className={`btn course-button ${selectedCourseType.toLowerCase()}-inverse mx-1 `}
                  >
                    View Details
                  </button>
                </Link>
              </div>
            )}
          >
            <>
              <div
                className="card-content mb-5 px-2"
                style={{ textAlign: "start" }}
              >
                {course.details}
              </div>
              {course.price && (
                <div style={{ textAlign: "justify" }}>
                  {/* <i className="pi pi-pound" style={{ color: "white" }} /> */}
                  <strong style={{ fontSize: "20px", color: "white" }}>
                    From Price{" "}
                    <i className="pi pi-pound" style={{ color: "white" }} />
                    {course.from_price}
                  </strong>
                </div>
              )}
            </>
          </Card>
        </div>
      ));
  };

  return (
    <div className="mt-5">
      <h1 className="text-center m-4">Courses</h1>
      <div className="d-flex justify-content-center m-4">
        <SelectButton
          value={value}
          onChange={handleCoursetypeChange}
          optionLabel="name"
          options={items}
          className="md:w-25rem d-flex flex-row justify-content-center align-items-center"
        />
      </div>
      {/* <Routes>
        <Route path="/:beginner" />
      </Routes> */}
      <div className="d-flex flex-wrap justify-content-center">
        {selectedCourseType && renderCourses()}
      </div>
    </div>
  );
};

export default Courses;

// import { Card } from "primereact/card";
// import { SelectButton } from "primereact/selectbutton";
// import React, { useEffect, useState } from "react";
// import { getAllCourses } from "../services/courses";

// const Courses = () => {
//   const [value, setValue] = useState(null);
//   const items = [
//     { name: "Beginner", value: "Beginner" },
//     { name: "Intermediate", value: "Intermediate" },
//     { name: "Refresher", value: "Refresher" },
//   ];
//   const [courses, setCourses] = useState([]);
//   const [selectedCourseType, setSelectedCourseType] = useState("");
//   const handleCoursetypeChange = (e) => {
//     setSelectedCourseType(e.target.value);
//     console.log(e.target.value);
//     setValue(e.target.value);
//   };
//   const getCourses = async () => {
//     await getAllCourses()
//       .then((data) => {
//         setCourses(data);
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };

//   useEffect(() => {
//     getCourses();
//   }, []);
//   const refresherCourses = courses.filter(
//     (course) => course.category === "Refresher"
//   );
//   const intermediateCourses = courses.filter(
//     (course) => course.category === "Intermediate"
//   );
//   const beginnerCourses = courses.filter(
//     (course) => course.category === "Beginner"
//   );
//   const footer = () => {
//     return (
//       <div className="d-flex justify-content-end">
//         <button className="">Book Now</button>
//       </div>
//     );
//   };
//   return (
//     <div>
//       <h1 className="text-center">Courses</h1>
//       <div className="d-flex justify-content-center">
//         <SelectButton
//           value={value}
//           onChange={handleCoursetypeChange}
//           optionLabel="name"
//           options={items}
//           // multiple
//           className="md:w-25rem d-flex flex-row justify-content-center align-items-center"
//         />
//       </div>
//       {selectedCourseType === "Beginner" && (
//         <div
//         //   className="card flex justify-content-center"
//         >
//           <Card
//             //   header={() => <strong className="mx-3 mt-4">48 hours Course</strong>}
//             title={() => {
//               return (
//                 <>
//                   <i
//                     className="pi pi-clock"
//                     style={{ color: "", fontSize: "20px" }}
//                   />{" "}
//                   48 Hours Course <br />
//                   <i
//                     className="pi pi-calendar"
//                     style={{ color: "", fontSize: "20px" }}
//                   />{" "}
//                   4-10 weeks duration
//                 </>
//               );
//             }}
//             subTitle={() => (
//               <>
//                 <i className="pi pi-pound" />
//                 <strong className="" style={{ fontSize: "20px" }}>
//                   500
//                 </strong>
//               </>
//             )}
//             className="md:w-25rem"
//             footer={footer}
//           >
//             <p>
//               You're eager to hop in the driver's seat and begin your learning
//               journey. This course is perfect for newcomers, providing ample
//               time for hands-on experience. It is a 48-hour program which is
//               flexible and duration can be tailored to suit your preferences.
//             </p>
//           </Card>
//         </div>
//       )}
//       <div
//       //   className="card flex justify-content-center"
//       >
//         <Card
//           //   header={() => <strong className="mx-3 mt-4">48 hours Course</strong>}
//           title={() => {
//             return (
//               <>
//                 <i
//                   className="pi pi-clock"
//                   style={{ color: "", fontSize: "20px" }}
//                 />{" "}
//                 48 Hours Course <br />
//                 <i
//                   className="pi pi-calendar"
//                   style={{ color: "", fontSize: "20px" }}
//                 />{" "}
//                 4-10 weeks duration
//               </>
//             );
//           }}
//           subTitle={() => (
//             <>
//               <i className="pi pi-pound" />
//               <strong className="" style={{ fontSize: "20px" }}>
//                 500
//               </strong>
//             </>
//           )}
//           className="md:w-25rem"
//           footer={footer}
//         >
//           <p>
//             You're eager to hop in the driver's seat and begin your learning
//             journey. This course is perfect for newcomers, providing ample time
//             for hands-on experience. It is a 48-hour program which is flexible
//             and duration can be tailored to suit your preferences.
//           </p>
//         </Card>
//       </div>
//     </div>
//   );
// };

// export default Courses;

// // <div
// // //   className="card flex justify-content-center"
// // >
// //   <Card
// //     //   header={() => <strong className="mx-3 mt-4">48 hours Course</strong>}
// //     title={() => {
// //       return (
// //         <>
// //           <i
// //             className="pi pi-clock"
// //             style={{ color: "", fontSize: "20px" }}
// //           />{" "}
// //           48 Hours Course <br />
// //           <i
// //             className="pi pi-calendar"
// //             style={{ color: "", fontSize: "20px" }}
// //           />{" "}
// //           4-10 weeks duration
// //         </>
// //       );
// //     }}
// //     subTitle={() => (
// //       <>
// //         <i className="pi pi-pound" />
// //         <strong className="" style={{ fontSize: "20px" }}>
// //           500
// //         </strong>
// //       </>
// //     )}
// //     className="md:w-25rem"
// //     footer={footer}
// //   >
// //     <p>
// //       You're eager to hop in the driver's seat and begin your learning
// //       journey. This course is perfect for newcomers, providing ample time
// //       for hands-on experience. It is a 48-hour program which is flexible
// //       and duration can be tailored to suit your preferences.
// //     </p>
// //   </Card>
// // </div>
