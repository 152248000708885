import React, { useState, useEffect, useRef, useContext } from "react";
import { MDBRow, MDBCol, MDBTooltip } from "mdbreact";
import { useNavigate, useParams } from "react-router-dom";
import MyOwnSelect from "../Components/misc/MyOwnSelect.js";
import getOptions from "../Components/misc/Options.js";
import LoaderModal from "../Components/custom/LoaderModal";
import { createRole, getRole, updateRole } from "../services/roles";
import { AbilityContext, Can } from "../configs/Ability-context.js";
import { getPermissions } from "../services/permissions";
import { ActionButton, Input } from "../Components/UI/Customs.jsx";
import { FloatLabel } from "primereact/floatlabel";
// import "../styles/Role.css";

const INITIAL_STATE = {
  allPermissions: [],
  selectedPermissions: [],
  name: "",
};

const Role = (props) => {
  const [
    {
      allPermissions,
      roleId,
      selectedPermissions,
      name,
      validationMessage,
      isLoading,
    },
    setState,
  ] = useState({
    ...INITIAL_STATE,
    validationMessage: "",
    isLoading: false,
  });

  let roleForm = useRef(null);
  let nameField = useRef(null);
  let { APO_role } = props;
  let navigate = useNavigate();
  let { role } = useParams();

  useEffect(() => {
    let _isMounted = true;
    const fetchData = async () => {
      if (APO_role === "update") {
        changeValue("isLoading", true);
        const { success, data } = role !== 1 && (await getRole(role));
        let ePermissions = data?.permissions.map((permission) => ({
          key: permission.id,
          label: permission.slug,
          action: permission.action,
          subject: permission.subject,
          value: permission.id,
        }));
        if (data) {
          setState((prevState) => ({
            ...prevState,
            name: data.name,
            selectedPermissions: ePermissions,
            isLoading: false,
          }));
        } else changeValue("isLoading", false);
        // data && fetchMeta()
      }
      fetchMeta();
    };
    const fetchMeta = async () => {
      const { success, data } = role != 1 && (await getPermissions());
      _isMounted && data && changeValue("allPermissions", data);
    };
    fetchData();
    return () => {
      _isMounted = false;
    };
  }, []);

  let permissionOptions = getOptions("permissions", allPermissions);

  const changeValue = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleInput = (e) => {
    let { value } = e.target;
    changeValue("name", value);
  };

  const handleSelectChange = (selectedOption) => {
    // console.log(selectedOption)
    let addedPermission = selectedOption
      ? selectedOption[selectedOption.length - 1]
      : false;
    // console.log(addedPermission)
    if (
      addedPermission &&
      addedPermission.action !== "read" &&
      addedPermission.action !== "manage" &&
      addedPermission.subject !== "userProfile" &&
      addedPermission.subject !== "sellerProfile"
    ) {
      let addedPermissionGroup = permissionOptions.find(
        (option) => option.label === addedPermission.subject
      );
      let readPermission = addedPermissionGroup?.options.filter(
        (option) => option.action === "read"
      );
      selectedOption = readPermission.length
        ? selectedOption.find(
            (option) => option.value === readPermission[0].value
          )
          ? selectedOption
          : [...selectedOption, ...readPermission]
        : selectedOption;
    }
    changeValue("selectedPermissions", selectedOption);
  };
  const ability = useContext(AbilityContext);
  const handleSubmit = async (e) => {
    e.preventDefault();
    let form = roleForm.current;
    if (!form.checkValidity()) {
      form.classList.add("was-validated");
      return;
    } else if (!selectedPermissions || selectedPermissions.length === 0) {
      setState((prevState) => ({
        ...prevState,
        validationMessage: "Please select at least one permission",
        selectedPermissions: null,
      }));
      return;
    } else {
      let Role = {
        name: name.toLowerCase(),
        permissionIds: selectedPermissions.map(
          (permission) => permission.value
        ),
      };
      // console.log(Role)

      const { success, message } =
        APO_role === "create"
          ? await createRole(Role)
          : APO_role === "update"
          ? await updateRole(role, Role)
          : "";
      changeValue("validationMessage", message);
      if (success && APO_role === "create") {
        setState((prevState) => ({
          ...prevState,
          name: "",
          selectedPermissions: [],
        }));
      }
      success
        ? setTimeout(() => {
            success && navigate("/user/roles");
          }, 1500)
        : nameField.current.focus();
    }
  };

  return (
    <Can I={APO_role === "create" ? "create" : "update"} a="roles">
      {/* // <Can I="manage" a="all"> */}
      {/* <div className="screen-center h-100vh container"> */}
      <div className="screen-center h-100vh container ">
        <form
          ref={roleForm}
          className="m-0 p-0 w-100 w-sm-75 w-md-50 text-center color-tertiary mx-auto"
          onSubmit={handleSubmit}
          noValidate
        >
          {/* <h2 className="text-black">{JSON.stringify(ability)}</h2> */}
          <h5 className=" fw-bold mb-3 mb-sm-4 text-black">
            {APO_role === "create" ? "New Role" : "Edit Role"}
          </h5>
          <MDBRow center className="m-0 p-0">
            <MDBCol middle sm="10" className="m-0 w-50">
              {/* <FloatLabel>
                <Input
                  id="name"
                  name={`name`}
                  value={name}
                  handleInput={handleInput}
                  setRef={(ref) => (nameField.current = ref)}
                  required
                  invalidMessage={`Please enter role name`}
                />
                <label for="name">{name ? "Name" : ""}</label>
              </FloatLabel> */}
              <Input
                label="Name"
                name={`name`}
                value={name}
                handleInput={handleInput}
                setRef={(ref) => (nameField.current = ref)}
                required
                invalidMessage={`Please enter role name`}
              />{" "}
              <MDBTooltip domElement tag="span" placement="right">
                <div className="mb-4 p-0">
                  <MyOwnSelect
                    options={permissionOptions}
                    optionsHeading={
                      selectedPermissions && selectedPermissions.length
                        ? "Select permissions"
                        : ""
                    }
                    handleChange={handleSelectChange}
                    placeHolder="Select permissions"
                    selectedValue={selectedPermissions}
                    className="border-radius-0"
                    withOutIcon
                    required
                    isMulti
                    //   isOptionDisabled={(option) =>
                    //     option.label.toLowerCase() === "manage_all"
                    //   }
                  />
                </div>
                <i>
                  Selecting manage option will give all permissions of that
                  entity. e.g, role having "manage_users" permission can perform
                  all actions on users.
                </i>
              </MDBTooltip>
            </MDBCol>
          </MDBRow>
          <MDBRow center className="mx-0 my-2 p-0">
            <MDBCol sm="10" className="m-0 p-0 row">
              <MDBCol size="6" className="m-0 text-left">
                <label className="p-0 validating-label">
                  {validationMessage}&nbsp;
                </label>
              </MDBCol>
              <MDBCol size="6" className="m-0 text-right">
                <ActionButton
                  // icon={'arrow-right'}
                  value={APO_role === "create" ? "Create" : "Update"}
                  // name="next"
                  className="bg-Secondary"
                  type="submit"
                />
              </MDBCol>
            </MDBCol>
          </MDBRow>
        </form>
      </div>
      <LoaderModal modalShow={isLoading} />
    </Can>
  );
};

export default Role;
