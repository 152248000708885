import React, { useContext, useEffect, useState } from "react";
import "../styles/Home.css";
import HeroSection from "../Components/HeroSection";
import AfterHeroSection from "../Components/AfterHeroSection";
import BenifitsSection from "../Components/BenifitsSection";
import FindCourseSection from "../Components/FindCourseSection";
import Reviews from "./Reviews";
import FAQPage from "./FaqPage";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import { UserContext } from "../contexts/UserContext";
import { Outlet, useLocation } from "react-router-dom";
import MoneyBackGuarantee from "../Components/MoneyBackGuarantee";
import DiscountPromosSection from "../Components/DiscountPromosSection";
import VideosSection from "../Components/VideosSection";
import CourseRecommenderSection from "../Components/CourseRecommenderSection";
import TrustPilotBadge from "../Components/TrustPilotBdge";
import GoogleReviewBadge from "../Components/GoogleReviewBadge";

const Home = ({ handleLogOut }) => {
  // eslint-disable-next-line
  const [collapsed, setCollapsed] = useState(true);
  useEffect(() => {
    sessionStorage.clear();
  }, []);
  const location = useLocation();
  const { isLoggedIn } = useContext(UserContext);
  const isHomePage = location.pathname === "/";

  return (
    <div>
      <Header handleLogOut={handleLogOut} isLoggedIn={isLoggedIn} />
      {isHomePage ? (
        <div style={{ marginTop: "-24px" }}>
          <HeroSection />
          <div
            className="mx-0"
            style={{
              backgroundColor: "#fff6e5",
            }}
          >
            <AfterHeroSection />
          </div>
          <div className={`home ${collapsed ? "collapsed" : ""}`}></div>
          <div>
            <BenifitsSection />
          </div>

          <FindCourseSection />
          <CourseRecommenderSection />
          <Reviews />
          <div className="container d-flex flex-wrap flex-row flex-container">
            <DiscountPromosSection />
            <MoneyBackGuarantee />
          </div>
          <VideosSection />
          <FAQPage />
        </div>
      ) : (
        <Outlet />
      )}
      <Footer />
    </div>
  );
};

export default Home;
