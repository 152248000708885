import React from "react";
import "../styles/ContactUs.css"; // Ensure the path is correct

const ContactUs = () => {
  return (
    <div className="contact-us">
      <h1>Contact Us</h1>
      <p>
        If you have any questions or need further information, please don't
        hesitate to get in touch with us:
      </p>
      <div className="contact-info">
        <div className="contact-item">
          <i className="pi pi-phone"></i>
          <a href="tel:03335773131">0333 577 3131</a>
        </div>
        <div className="contact-item">
          <i className="pi pi-envelope"></i>
          <a href="mailto:info@pass-swiftly.com">info@pass-swiftly.com</a>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
