// FAQPage.js
import React from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { PrimeIcons } from "primereact/api";
import "../styles/FaqPage.css";
import { Card } from "primereact/card";

const FAQPage = () => {
  const faqItems = [
    {
      question: "Is Intensive Driving Course for me?",
      answer:
        "This option is perfect if you're aiming to pass your test quickly without investing months or years in learning.",
    },
    {
      question: "What is intensive driving course?",
      answer:
        "An intensive driving course, also referred to as a crash course or fast-track driving course, compresses the learning period into a short timeframe. Unlike the traditional approach of weekly lessons spread over months, intensive driving courses often consist of several hours of lessons per day, typically spanning one to two weeks. Passswiftly has successfully assisted hundreds of individuals in obtaining their driving licenses through their intensive driving programs.",
    },
    {
      question: "Price of intensive driving course?",
      answer:
        "The price of an intensive driving course varies depending on the number of hours needed, typically ranging from 10 to 48 hours. One of the benefits of PassSwiftly's pricing for intensive driving courses is its all-inclusive nature: a single fee covers everything from theory and practical driving tests to all your lessons. When you book a PassSwiftly course, you won't need to manage cash for each lesson—you can choose to pay conveniently through monthly instalments.",
    },
    {
      question: "How to choose suitable course for myself?",
      answer:
        "Our website is designed to help you find the best course based on your abilities. Alternatively, one of our Booking Managers can personally discuss your needs with you and recommend the most suitable course.",
    },
    {
      question: "Timing for my driving lessons?",
      answer:
        "Lesson times typically range from 8am to 6pm, allowing you to schedule them without needing to take extensive time off from work. We can coordinate this together based on your availability.",
    },
    {
      question:
        "What if I only have one or two days off a week, can I still do lessons with you?",
      answer:
        "Certainly! You have the option to enroll in our semi-intensive course, where classes are scheduled one to two days per week for 3-5 hours each day.",
    },
    {
      question: "Can I pass my driving test in 2 weeks?",
      answer:
        "Numerous learners have completed their driving lessons in as little as 2 weeks! If you quickly grasp new skills and can dedicate yourself to 4 to 5-hour lessons on consecutive days, you could potentially finish one of our beginner courses in just 2 weeks.",
    },
    {
      question: "Are you guaranteed to pass on a crash course?",
      answer:
        "Success depends on the individual's performance during the test, which is beyond their control of driving school. Claims of 'guaranteed pass' for intensive driving courses often come with extensive terms and conditions. At PassSwiftly, we prioritize transparency and do not offer 'guaranteed pass' courses.",
    },
    {
      question:
        "Which type of car should I learn to drive: manual or automatic?",
      answer:
        "It ultimately depends on your preferences. Choosing a manual car means you'll gain proficiency in both manual and automatic vehicles, though it requires mastering additional skills. In contrast, automatic cars are easier to operate and are a great choice for individuals who may feel anxious about learning to drive.",
    },
  ];

  return (
    <div className="faq-container mt-5 container">
      <h2 className="faq-text-center mb-4">Frequently Asked Questions</h2>
      <Accordion>
        {faqItems.map((item, index) => (
          <AccordionTab key={index} header={item.question} className="py-1">
            <p>{item.answer}</p>
          </AccordionTab>
        ))}
      </Accordion>
    </div>
  );
};

export default FAQPage;
